import { INIT_CONFIRMATION, TOGGLE_CONFIRMATION, CONFIRMATION_DONE, 
	RESET_CONFIRMATION, RESET_ALL } from '../Constants/action-types'
import {status, state} from 'Scripts/CommonComponents/ApplicationForm/Constants/sharedInitialState'

const initialState = Object.assign({
	id: 'confirmation',
	status
  }, state)

const confirmation = (state = initialState, action) => {		
	switch (action.type) {
		case INIT_CONFIRMATION: 
			return Object.assign({}, state, { 
				choosenStep: action.availableSteps[0],
				parentStep: action.parentStep,
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
			})
		case TOGGLE_CONFIRMATION:
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, 
					{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
			})
		case CONFIRMATION_DONE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
			})
		case RESET_CONFIRMATION:
		case RESET_ALL:
			return initialState
    	default:
			return state
	}
}

export default confirmation