import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const theme = createTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				color: '#18161F',
				height: '9px',
				fontSize: '13px',
				fontFamily: [
					'Muli-Regular',
					'sans-serif'
				].join(','),
				textTransform: 'capitalize',
			},
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Muli-Regular',
			'sans-serif'
		].join(','),
		fontSize: '14px',
		fontSize: '1rem',
		fontWeight: 'normal',
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
			disableTouchRipple: true,
		},
	},
})

const styles = theme => ({
	margin: {
		margin: '0',
		padding: '5px 0 5px 0',
		verticalAlign: 'baseline',
	},
	cssRoot: {
		minWidth: '0',
		minHeight: '0',
		lineHeight: 'inherit',
		fontSize: '14px',
		fontSize: '1rem',
		fontWeight: 'normal',
		color: '#18161F',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'underline',
		},
	},
})

class LanguageSelectorComponent extends Component {
	constructor(props) {
		super(props)

		this.state = {
			anchorEl: null,
		}

		this.handleClick = event => {
			this.setState({ anchorEl: event.currentTarget })
		}

		this.handleClickInside = event => {
			window.open(event.currentTarget.getAttribute('data-url'), '_self')
			this.setState({ anchorEl: null })
		}

		this.handleClose = () => {
			this.setState({ anchorEl: null })
		}
	}

	render() {

		const { classes } = this.props
		const { anchorEl } = this.state

		if (this.props.multipleLanguage) {
			return (
				<MuiThemeProvider theme={theme}>
					<span className='language-selector'>
						<Button
							className={classNames(classes.margin, classes.cssRoot)}
							aria-owns={anchorEl ? 'language-menu' : null}
							aria-haspopup='true'
							onClick={this.handleClick}
						>

						{this.props.currentLanguage}
						</Button>

						<Menu
							id='language-menu'
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={this.handleClose}

						>

						{this.props.availableLanguages.map((lang, index) =>
							<MenuItem key={index} onClick={this.handleClickInside} data-url={lang.Url}><span lang={lang.LangCode}>{lang.Name}</span></MenuItem>
						)}
						</Menu>
					</span>
				</MuiThemeProvider>
			)
		} else {
			return (
				<MuiThemeProvider theme={theme}>
					<span className='language-selector'>
						{this.props.availableLanguages.map((lang, index) =>
							<Button
								className={classNames(classes.margin, classes.cssRoot)}
								key={index}
								aria-owns={anchorEl ? 'language-menu' : null}
								aria-haspopup='false'
								data-url={lang.Url}
								onClick={this.handleClickInside}
								role="menuitem"
							>
								<span lang={lang.LangCode}>{lang.Name}</span>
							</Button>
						)}
					</span>
				</MuiThemeProvider>
			)
		}
	}
}

LanguageSelectorComponent.propTypes = {
	multipleLanguage: PropTypes.bool,
	currentLanguage: PropTypes.string,
	availableLanguages: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		url: PropTypes.string,
		langCode: PropTypes.string,
	})),
}

export default withStyles(styles)(LanguageSelectorComponent)