export default class MonitorService {
    constructor() {
        this.appInsights = window.appInsights ||
            {
                trackException: (error, customProps) => {
                    console.error(error)
					console.log('Custom error properties TEST: ', customProps)
                }
            }
    }

    trackException(error, customProps) {
        this.appInsights.trackException(error, null, customProps || {})
    }
}