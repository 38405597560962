import { setCookie } from 'Scripts/Helpers/cookieHelper'
import { getCookie } from 'Scripts/Helpers/cookieHelper'

export function initNotificationBlock() {
	let siteNotificationBlocks = document.getElementsByClassName('js-notification-block')
	const siteNotificationBlocksArray = Array.from(siteNotificationBlocks)

	let blocksToRemove = []

	if (siteNotificationBlocks !== null) {
		for (let siteNotificationBlock of siteNotificationBlocks) {
			let siteNotificationBlockBtn = siteNotificationBlock.querySelector('.js-notification-block-button')
			let identifier = siteNotificationBlockBtn.getAttribute('data-identifier')
			let cookieName = 'NotificationCookie-' + identifier

			checkCookie(siteNotificationBlock, cookieName)

			siteNotificationBlockBtn.addEventListener('click', function () {
				setCookie(cookieName, 'true', 30)
				this.closest('.js-notification-block').remove()
			}, false)
		}
		removeNotifications()
	}

	function removeNotifications() {
		siteNotificationBlocksArray.filter(val => blocksToRemove.includes(val)).map(val => val.remove())
	}

	function checkCookie(block, cookieName) {
		let cookie = getCookie(cookieName)

		if (cookie === '') {
			block.classList.add('is-visible')
		}
		else {
			blocksToRemove.push(block)
		}
	}
}