import { hot } from 'react-hot-loader'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { rootReducer } from './Reducers'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import BasketApiService from './Services/BasketApiService'

const BasketContainer = lazy(() => import('./Containers/BasketContainer'))

const persistConfig = {
	key: 'basket_root',
	storage: storageSession,
	stateReconciler: hardSet,
	blacklist: ['exits']
}

const HotBasketContainer = hot(module)(BasketContainer)

export function initBasketComponent() {
	const basketPageApp = document.getElementById('basketPageApp')

	if (basketPageApp !== null) {
		const basketApiService = new BasketApiService(basketPageViewModel.CurrencyServiceUrl, basketPageViewModel.AuthServiceUrl,
			basketPageViewModel.PaymentServiceUrl, basketPageViewModel.CustomerServiceUrl)
		const persistedReducer = persistReducer(persistConfig, rootReducer)
		const store = createStore(persistedReducer,
			window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
		const persistor = persistStore(store)

		ReactDOM.render(
			<Provider store={store}>
				<PersistGate loading={<div>Loading shopping cart...</div>} persistor={persistor}>
					<Suspense fallback={<div></div>}>
					<HotBasketContainer
						basketApiService={basketApiService}
						headerLabel={basketPageViewModel.HeaderLabel}
						denominationInfoText={basketPageViewModel.DenominationInfoText}
						teaserText={basketPageViewModel.TeaserText}
						confirmationPageUrl={basketPageViewModel.ConfirmationPageUrl}
						htmlTermsText={basketPageViewModel.HtmlTermsText}
						termsLink={basketPageViewModel.TermsLink}
						baseCountryCode={basketPageViewModel.BaseCountryCode}
						baseTargetCurrencyCode={basketPageViewModel.BaseTargetCurrencyCode}
						deliveryButtonText={basketPageViewModel.DeliveryButtonText}
						deliveryTeaserText={basketPageViewModel.DeliveryTeaserText}
						transactionQuestionsTeaserText={basketPageViewModel.TransactionQuestionsTeaserText}
						transactionQuestionsText={basketPageViewModel.TransactionQuestionsText}
						mobileHeader={basketPageViewModel.MobileHeader}
						paymentButtonText={basketPageViewModel.PaymentButtonText}
						chooseStoreButtonText={basketPageViewModel.ChooseStoreButtonText}
						paymentTeaserText={basketPageViewModel.PaymentTeaserText}
						offerDescription={basketPageViewModel.OfferDescription}
						offerDescriptionPhone={basketPageViewModel.OfferDescriptionPhone}
						topDescription={basketPageViewModel.TopDescription}
						topDescriptionPhone={basketPageViewModel.TopDescriptionPhone}
						deliverByMailInformationText={basketPageViewModel.DeliverByMailInformationText}
						collectAtStoreInformationText={basketPageViewModel.CollectAtStoreInformationText}
						payInStoreInformationText={basketPageViewModel.PayInStoreInformationText}
						autoSelectPayment={basketPageViewModel.AutoSelectPayment}
						enableDirectSwishConnection={basketPageViewModel.EnableDirectSwishConnection}
						enableSwish={basketPageViewModel.EnableSwish}
						enableMobilePay={basketPageViewModel.EnableMobilePay}
						enableZimpler={basketPageViewModel.EnableZimpler}
						enableNetsPayment={basketPageViewModel.EnableNetsPayment}
						uniqueSellingPoints={basketPageViewModel.UniqueSellingPoints}
						collectAtStorePreamble={basketPageViewModel.CollectAtStorePreamble}
						deliverByMailPreamble={basketPageViewModel.DeliverByMailPreamble}
						payInStorePreamble={basketPageViewModel.PayInStorePreamble}
						cardPaymentPreamble={basketPageViewModel.CardPaymentPreamble}
						swishPaymentPreamble={basketPageViewModel.SwishPaymentPreamble}
						transferPaymentOnlinePreamble={basketPageViewModel.TransferPaymentOnlinePreamble}
						reservationMinDays={basketPageViewModel.ReservationMinDays}
						cardPaymentInformationText={basketPageViewModel.CardPaymentInformationText}
						swishPaymentInformationText={basketPageViewModel.SwishPaymentInformationText}
						transferPaymentInformationText={basketPageViewModel.TransferPaymentInformationText}
						cardLimitInformationText={basketPageViewModel.CardLimitInformationText}
						zimplerSiteInformationText={basketPageViewModel.ZimplerSiteInformationText}
						identificationButtonText={basketPageViewModel.IdentificationButtonText}
						identificationMoreInformationText={basketPageViewModel.IdentificationMoreInformationText}
						identificationMoreInformationLabel={basketPageViewModel.IdentificationMoreInformationLabel}
						identificationTeaserText={basketPageViewModel.IdentificationTeaserText}
						approveButtonText={basketPageViewModel.ApproveButtonText}
						approveTeaserText={basketPageViewModel.ApproveTeaserText}
						approveOrderTeaserText={basketPageViewModel.ApproveOrderTeaserText}
						approveSwishOrderTeaserText={basketPageViewModel.ApproveSwishOrderTeaserText}
						orderConfirmationPageUrl={basketPageViewModel.OrderConfirmationPageUrl}
						locateStoreHeader={basketPageViewModel.LocateStoreHeader}
						locateStoreBody={basketPageViewModel.LocateStoreBody}
						locateStoreInitialFocusLongitude={basketPageViewModel.LocateStoreInitialFocusLongitude}
						locateStoreInitialFocusLatitude={basketPageViewModel.LocateStoreInitialFocusLatitude}
						locateStoreInitialZoomLevel={basketPageViewModel.LocateStoreInitialZoomLevel}
						locateStoreStoreZoomLevel={basketPageViewModel.LocateStoreStoreZoomLevel}
						googleMapsApiKey={basketPageViewModel.GoogleMapsApiKey}
						GDPRText={basketPageViewModel.GDPRText}
						GDPRUrl={basketPageViewModel.GDPRUrl}
						redirectUrl={basketPageViewModel.RedirectUrl}
						checkboxCustomersOwnMoney={basketPageViewModel.CheckboxCustomersOwnMoney}
						checkboxCustomersOwnSwish={basketPageViewModel.CheckboxCustomersOwnSwish}
						defaultCampaign={basketPageViewModel.DefaultCampaign}
						quickBasket={basketPageViewModel.QuickBasket}
						campaignBasket={basketPageViewModel.CampaignBasket}
						errorTitle={basketPageViewModel.ErrorTitle}
						errorMessage={basketPageViewModel.ErrorMessage}
						kycTitle={basketPageViewModel.KycTitle}
						kycPreamble={basketPageViewModel.KycPreamble}
						exitGeneralError={basketPageViewModel.ExitGeneralError}
						exitTooYoung={basketPageViewModel.ExitTooYoung}
						exitAMLStatusYellow={basketPageViewModel.ExitAMLStatusYellow}
						exitAMLStatusRed={basketPageViewModel.ExitAMLStatusRed}
						exitPersonNotFound={basketPageViewModel.ExitPersonNotFound}
						featureShowMarketingCheckbox={basketPageViewModel.FeatureShowMarketingCheckbox}
						onlyAllowStorePickup={basketPageViewModel.OnlyAllowStorePickup}
					/>
					</Suspense>
				</PersistGate>
			</Provider>,
			basketPageApp
		)
	}
}