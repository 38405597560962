import { combineReducers } from 'redux'

import {
	PRIMARY_, _EMPLOYMENT_STATUS, _MARITAL_STATUS, _ACCOUNT_PURPOSE, _ASSETS_AND_DEBTS, SECONDARY_,
	PURPOSE_AND_RESOLVE_LOANS_, _PEP_QUESTIONS, _RESOLVE_LOANS, _BANK_ACCOUNT_INFORMATION, NEXT_STEP
} from '../Constants/action-types'
import transitions from './stateMachine'
import loanCalculator from './loanCalculator'
import mainInformationFor from 'Scripts/CommonComponents/ApplicationForm/Reducers/mainInformation'
import personalInformationFor from 'Scripts/CommonComponents/ApplicationForm/Reducers/personalInformation'
import secondaryApplicantQuestion from 'Scripts/CommonComponents/ApplicationForm/Reducers/secondaryApplicantQuestion'
import formReducerFor from 'Scripts/CommonComponents/ApplicationForm/Reducers/formReducerFor'
import confirmation from './confirmAndSignReducer'
import exits from 'Scripts/CommonComponents/ApplicationForm/Reducers/exits'
import { reducer as notificationMessage } from 'Scripts/CommonComponents/NotificationMessageComponent/reducer'
import { states } from '../Constants/states'
import { trackEvent, trackPageView } from 'Scripts/Shared/analyticsEventTracking'

const combinedReducers = combineReducers({
	transitions,
	loanCalculator,
	primaryApplicant: combineReducers({
		mainInformation: mainInformationFor(PRIMARY_),
		personalInformation: personalInformationFor(PRIMARY_),
		maritalStatus: formReducerFor(PRIMARY_, _MARITAL_STATUS),
		employmentStatus: formReducerFor(PRIMARY_, _EMPLOYMENT_STATUS),
		pepQuestions: formReducerFor(PRIMARY_, _PEP_QUESTIONS),
	}),
	secondaryApplicant: combineReducers({
		secondaryApplicantQuestion: secondaryApplicantQuestion,
		mainInformation: mainInformationFor(SECONDARY_),
		personalInformation: personalInformationFor(SECONDARY_),
		maritalStatus: formReducerFor(SECONDARY_, _MARITAL_STATUS),
		employmentStatus: formReducerFor(SECONDARY_, _EMPLOYMENT_STATUS),
		pepQuestions: formReducerFor(SECONDARY_, _PEP_QUESTIONS),
	}),
	purposeAndResolveLoans: combineReducers({
		mainInformation: mainInformationFor(PURPOSE_AND_RESOLVE_LOANS_),
		accountPurpose: formReducerFor(PURPOSE_AND_RESOLVE_LOANS_, _ACCOUNT_PURPOSE),
		//assetsAndDebts: formReducerFor(PURPOSE_AND_RESOLVE_LOANS_, _ASSETS_AND_DEBTS),
		resolveLoans: formReducerFor(PURPOSE_AND_RESOLVE_LOANS_, _RESOLVE_LOANS),
		bankAccountInformation: formReducerFor(PURPOSE_AND_RESOLVE_LOANS_, _BANK_ACCOUNT_INFORMATION),
	}),
	confirmation,
	exits,
	notificationMessage
})

const notifyGA = (step, state) => {
	switch (step) {
		case states.start:
		case states.loanCalculator:
			trackEvent('Loan', 'initLoan', 'Start', null)
			break

		case states.primaryInformation:
			trackPageView('loaninfo', 'Loan Information')
			trackEvent('Loan', 'loanCalculation', 'Start', state.loanCalculator.amount)
			trackEvent('Loan', 'initLoanInformation', 'Loan Information', null)
			break

		case states.personalInformation:
		case states.maritalStatus:
		case states.employmentStatus:
		case states.pepQuestions:
			break

		case states.secondaryInformation:
			trackEvent('Loan', 'initLoanCOapplicant', 'Loan Information', null)
			trackPageView('coapplicants', 'Loan Coapplicants')
			break

		case states.personalInformationCoApplicant:
		case states.maritalStatusCoApplicant:
		case states.employmentStatusCoApplicant:
		case states.pepQuestionsCoApplicant:
			break

		case states.purposeAndResolveLoans:
			trackEvent('Loan', 'initLoanPurpose', 'Loan CO Applicant', null)
			trackPageView('purpose', 'Loan Purpose')
			break

		case states.bankAccountInformation:
		case states.accountPurpose:
		case states.resolveLoans:
			break

		case states.confirmation:
			trackEvent('Loan', 'initLoanConfirmation', 'Loan Confirmation', null)
			trackPageView('loanconfirmation', 'Loan Confirmation')
			break
			
		case states.loanOffer:
		default:
			break
	}
}


export const rootReducer = (state, action) => {
	switch (action.type) {
		//implement action-logic shared between all reducers, i.e. google analytics, reset, ...
		case NEXT_STEP:
			notifyGA(action.payload, state)
			break
		default:
			break
	}
	return combinedReducers(state, action)
}