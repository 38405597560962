export const states = {
	start: 'start',
	cart: 'cart',
	deliveryStore: 'deliveryStore',
	deliveryPostal: 'deliveryPostal',
	delivery3rd: 'delivery3rd',
	paymentCard: 'paymentCard',
	paymentSwish: 'paymentSwish',
	paymentMobilePay: 'paymentMobilePay',
	paymentDirect: 'paymentDirect',
	paymentStore: 'paymentStore',
	identificationDetailed: 'identificationDetailed',
	identificationBank: 'identificationBank',
	transactionQuestions: 'transactionQuestions',
	approveOrder:'approveOrder',
	approveReservation:'approveReservation'
}