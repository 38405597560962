import { trackEvent } from 'Scripts/Shared/analyticsEventTracking'

export function initShowHideContent() {
	let showMoreToggles = document.getElementsByClassName('js-show-more')

	if (showMoreToggles !== null) {
		for (let showMoreTrigger of showMoreToggles) {

			showMoreTrigger.addEventListener('click', function () {
				let containerToShow = this.closest('div')
				containerToShow.classList.toggle('display-all')
				trackEvent('Show-more-click', window.location.pathname, this.textContent, null)
			}, false)
		}
	}
}