export const states = {
	start:'start',
	loanCalculator: 'loanCalculator',
	primaryInformation: 'primaryInformation',
	personalInformation: 'personalInformation',
	maritalStatus:'maritalStatus',
	employmentStatus:'employmentStatus',
	pepQuestions: 'pepQuestions',
	secondaryInformation: 'secondaryInformation',
	personalInformationCoApplicant: 'personalInformationCoApplicant',
	maritalStatusCoApplicant:'maritalStatusCoApplicant',
	employmentStatusCoApplicant:'employmentStatusCoApplicant',
	pepQuestionsCoApplicant: 'pepQuestionsCoApplicant',
	purposeAndResolveLoans: 'purposeAndResolveLoans',
	//assetsAndDebts:'assetsAndDebts',
	accountPurpose: 'accountPurpose',
	resolveLoans: 'resolveLoans',
	bankAccountInformation: 'bankAccountInformation',
	confirmation:'confirmation',
	loanOffer: 'loanOffer',
	sign:'sign'
}