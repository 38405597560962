import {INIT_CART, ADD_TO_CART, ADD_TO_CAMPAIGN, UPDATE_CART, 
	UPDATE_PRICE, REMOVE_INCOMPATIBLE_CAMPAGINS,TOGGLE_CART,
	CART_DONE, RESET_ALL} from '../Constants/action-types'
import { status, state } from '../Constants/sharedInitialState'

import { calculateSummary } from '../Services/BasketSummaryHelper'


const initialState = Object.assign({
	id: 'cart',
	items: [],
	discounts:[],
	total: 0,
	status
}, state)

const cart = (state = initialState, action) => {
	switch (action.type) {
			case ADD_TO_CART:
				return Object.assign({}, state, { 
					items: state.items.concat(action.payload)
				})
			case INIT_CART: 
				return Object.assign({}, state, { 
					items: action.payload,
					total: calculateSummary(action.payload.map(i => i.fromAmount)),
					parentStep: action.parentStep,
					choosenStep: action.availableSteps[0],
					discounts: [],
					availableSteps: Object.assign([], action.availableSteps),
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
				})
			case UPDATE_CART: 
				return Object.assign({}, state, { 
					items: action.payload,
					total: calculateSummary(action.payload.map(i => i.fromAmount)),
					status: Object.assign({}, state.status, {done:false})  
				})
			case UPDATE_PRICE: 
				return Object.assign({}, state, { 
					items: action.payload,
					total: calculateSummary(action.payload.map(i => i.fromAmount))
				})
			case ADD_TO_CAMPAIGN:
				return Object.assign({}, state, { 
					discounts: state.discounts = action.payload
				})
			case REMOVE_INCOMPATIBLE_CAMPAGINS:
				return Object.assign({}, state, { 
					discounts : state.discounts.filter(x=> !x.attributes.unique)
			})
			case TOGGLE_CART: 
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, 
						{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
				})
			case CART_DONE: 
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
				})
			case RESET_ALL:
				return initialState
    		default:
				return state
  }
}


export default cart