import { HttpService, searchServiceApi } from 'Scripts/Shared'
import { get } from 'lodash'

export class SearchApiService {
	constructor(searchServiceUrl) {
		this.searchServiceUrl = searchServiceUrl
		this.http = new HttpService()
	}

	getSearchSuggestions(searchTerm) {
		const { url } = searchServiceApi.autocomplete
		const searchSuggestionsUrl = this.searchServiceUrl + url

		let lang = window.currentCulture

		if (!lang) {
			lang = 'sv'
		}

		return this.http.get(searchSuggestionsUrl + '/' + searchTerm + '?lang=' + lang)
			.then((response) => this.mapToSuggestions(response))
	}

	mapToSuggestions(response) {
		return response.data.attributes.suggestions.reduce(function (result, data, index) {
			result.push({
				suggestion: {
					id: index,
					query: get(data, 'query', '')
				}
			})
			return result
		}, [])
	}
}
