export function initTableWrapp() {
	let contentBlockTables = document.querySelectorAll('.content-block table')

	if (contentBlockTables == null) {
		return
	}
	else {
		for (let table of contentBlockTables) {
			let wrapper = document.createElement('div')
			wrapper.classList.add('table-wrapper')
			table.parentNode.insertBefore(wrapper, table)
			wrapper.appendChild(table)
		}
	}
}