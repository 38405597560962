export const states = {
	mainInformation: 'mainInformation',
	personalInformation: 'personalInformation',
	pepQuestions:'pepQuestions',
	taxQuestions:'taxQuestions',
	fellowInformation: 'fellowInformation',
	personalInformation2:'personalInformation2',
	pepQuestions2: 'accountQuestions2',
	taxQuestions2: 'taxQuestions2',
	accountQuestions:'accountQuestions',
	confirmation: 'confirmation',
	done: 'done'
}