export default class SearchBar {

	constructor() {
		this.initSearchBar()
	}

	initSearchBar() {
		this.showSearchBar
		this.hideSearchBar

		let searchBarToogleButtonDesktop = document.getElementById('js-toggle-search-bar-desktop')

		if (searchBarToogleButtonDesktop) {
			searchBarToogleButtonDesktop.addEventListener('click', this.showSearchBar)
		}
	}

	showSearchBar() {
		let searchBarDesktop = document.getElementById('search-bar-desktop')
		searchBarDesktop.classList.toggle('search-bar-desktop--is-open')
		searchBarDesktop.querySelector('input').focus()
	}

	hideSearchBar() {
		let searchBarDesktop = document.getElementById('search-bar-desktop')
		searchBarDesktop.classList.remove('searchbar-desktop--is-open')
	}
}