import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

const ExchangeConverter = lazy(() => import('./Components/ExchangeConverter')) 

const ExchangeConverterComponent = ({currencyInfo}) => {
	const [isSummaryOpen, setIsSummaryOpen] = useState(false)
	const [extend, setExtend] = useState(false)
	const [isMobile, setIsMobile] = useState(window.screen.width < 900)

	useEffect(() => {
		function handleResize(){
			setIsMobile(window.screen.width < 900  )	
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		  
	  }  
  
	}, [])

	const imgClasses = `${isSummaryOpen && isMobile ? 'hero-converter-block__mobileImg': ''}`
	const background = 
		isMobile && currencyInfo.MobileImageUrl ? 
			`url(${currencyInfo.MobileImageUrl}?w=${window.screen.width})`
		:  currencyInfo.DesktopImageUrl ? 
			`url(${currencyInfo.DesktopImageUrl}?w=${window.screen.width})` 
		: '#ffcf00'

	const preamble = extend ? 'preamble-show' : 'preamble'		

	return (
		<div className='hero-converter-block hero-area__content--align'>
			<div 
              className={`hero-converter-block__image ${imgClasses}`} 
              style={{
                background,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              >
            </div>
			<div className="hero-header__text">
				<div className='inner-container'>
					<h1>{currencyInfo.Title}</h1>
						<p className={`${preamble}`}>
							{currencyInfo.Preamble}
						</p>
						{!extend ? 
							<div className="extend show-more-link" onClick={() => setExtend(!extend)}>
								<span>{resources.Read_more}</span>
								<span className="icon icon-chevron-down"></span>
							</div>

						: null}
				</div>
			</div>
			<Suspense fallback={<div></div>}>
                <ExchangeConverter 
					baseCountryCode={currencyInfo.BaseCountryCode}
					selectedCurrency={currencyInfo.SelectedCurrency ?
						currencyInfo.SelectedCurrency :
						currencyInfo.BaseTargetCurrencyCode}
					selectedDestination={currencyInfo.SelectedDestination}
					basketPageUrl={currencyInfo.BasketPageUrl}
					currencyServiceUrl={currencyInfo.CurrencyServiceUrl}
					findStorePageUrl={currencyInfo.FindStorePageUrl}
					customerCarePageUrl={currencyInfo.CustomerCarePageUrl}
					initiallySelling={currencyInfo.InitiallySelling}
					isOnCurrencyConverterPage={currencyInfo.IsOnCurrencyConverterPage}
					currencyBuybackPageUrl={currencyInfo.CurrencyBuybackPageUrl}
					currencyConverterPageUrl={currencyInfo.CurrencyConverterPageUrl}
					titleBuy={currencyInfo.TitleBuy}
					teaserBuy={currencyInfo.TeaserBuy}
					titleSell={currencyInfo.TitleSell}
					teaserSell={currencyInfo.TeaserSell}
					fromAmount={currencyInfo.FromAmount}
					toAmount={currencyInfo.ToAmount}
					exampleExchangeAmount = {currencyInfo.ExampleExchangeAmount}
                    exampleTargetCurrencyExchangeAmount = {currencyInfo.ExampleTargetCurrencyExchangeAmount}
                    currencyLinks = {currencyInfo.CurrencyLinks}
				/>
			</Suspense>
		</div>
	)
}

const HotHeroConverterIndex = hot(ExchangeConverterComponent)

export function initExchangeConverterBlockComponent() {
	const currencyConverter = document.getElementById('exchange-currency-converter')

	if (currencyConverter !== null) {
		let currencyInfo = heroConverterModel

		ReactDOM.render(
			<HotHeroConverterIndex currencyInfo={currencyInfo} />
			,
			currencyConverter
		)
	}
}