import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { rootReducer } from './Reducers'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import {initNotificationMessage} from 'Scripts/CommonComponents/NotificationMessageComponent'

const persistConfig = {
	key: 'loan_root',
	storage: storageSession,
	stateReconciler: hardSet,
	blacklist: ['exits']
}

const LoanContainer = lazy(() => import('./Containers/LoanContainer'))
const HotLoanContainer = hot(LoanContainer)

export function initLoanApp() {
	const loanApp = document.getElementById('loanApp')

	if (loanApp !== null) {
		const persistedReducer = persistReducer(persistConfig, rootReducer)
		const store = createStore(persistedReducer, 
			window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
		const persistor = persistStore(store)

		initNotificationMessage(store)	

		ReactDOM.render(
			<Provider store={store}>
				<PersistGate loading={<div>Loading loan application...</div>} persistor={persistor}>
					<Suspense fallback={<div></div>}>
						<HotLoanContainer
							loanViewModel={loanViewModel}
						/>
					</Suspense>
				</PersistGate>
			</Provider>,
			loanApp
		)
	}
}