import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import SiteSelectorComponent from './Components/SiteSelectorComponent'

const HotSiteSelectorComponent = hot(SiteSelectorComponent)

export function initFooterComponent() {
	let siteSelectorComponent = document.getElementById('country-site-selector')

	if (siteSelectorComponent !== null) {
		ReactDOM.render(
			<HotSiteSelectorComponent
				currentSite={footerModel.SiteName}
				availableSites={footerModel.ForexSitesLinks}
			/>,
			siteSelectorComponent
		)
	}
}