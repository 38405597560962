export function initContainerAccordionBlocks() {
	let accordionContainers = document.getElementsByClassName('js-accordions')

	if (accordionContainers == null || accordionContainers.length == 0) {
		return
	}
	else {
		for (let accordionContainer of accordionContainers) {
			let accordionBlocks = accordionContainer.getElementsByClassName('js-accordion')

			if (accordionBlocks == null || accordionBlocks.length == 0) {
				return
			}
			else {
				addCollapseFunctionality(accordionBlocks)
			}
		}
	}

	function addCollapseFunctionality(accordionBlocks) {
		let index = 0

		for (let accordionBlock of accordionBlocks) {
			let collapseToggles = accordionBlock.getElementsByClassName('js-toggle-block')
			accordionBlock.classList.toggle('display-as-accordion')

			if (index == 0) {
				accordionBlock.classList.toggle('is-open')
			}

			for (let collapseToggle of collapseToggles) {
				collapseToggle.addEventListener('click', function () {
					accordionBlock.classList.toggle('is-open')
				}, false)
			}
			index++
		}
	}
}