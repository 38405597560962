import { SHOW, HIDE } from './constants'

const initialState = {
    show: false,
    message: undefined
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW:
            return Object.assign({}, state, {
                show: true,
                message: action.message
            })
        case HIDE:
            return Object.assign({}, state, {
                show: false,
                message: undefined
            })
        default:
            return state
    }
}