import { HttpService, loanServiceApi } from 'Scripts/Shared'

export default class LoanService {
	constructor(loanServiceUrl) {
		this.loanServiceUrl = loanServiceUrl
        this.http = new HttpService()
    }
	
	getFormValues() {
		const formValuesUrl = this.loanServiceUrl + loanServiceApi.formValues.url
		const options = {lang:currentCulture}
		const query = this.http.createQuery(options)
		return this.http.get(formValuesUrl + query)
	}

}