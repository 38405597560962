import { UPDATE_SECONDARY_APPLICANT, SECONDARY_, RESET_ALL } from 'Scripts/CommonComponents/ApplicationForm/Constants/action-types'

const initialState = {
	answer: undefined,
	answered: false
}

const secondaryApplicantQuestion = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SECONDARY_APPLICANT:
			return {answer: action.answer, answered: true}
		case SECONDARY_ + RESET_ALL:
		case RESET_ALL:
			return initialState
	  	default:
			return state
	}
}

export default secondaryApplicantQuestion