import { ALL_, SAVE, _ACCOUNT_QUESTIONS, SAVE_KYC_PROOF, PRIMARY_, SECONDARY_ } from '../Constants/action-types'

const initialState = {
	id: 'kycQuestions',
	questions: [],
	meta: {
		formId: null,
		productType: null,
	},
	primaryKycProof: null,
	secondaryKycProof: null
}

const kycQuestions = (state = initialState, action) => {		
	switch (action.type) {
		case ALL_ + SAVE + _ACCOUNT_QUESTIONS:
			return Object.assign({}, state, {
				meta: action.meta,
				questions: action.questions
			})
		case PRIMARY_ + SAVE_KYC_PROOF: 
			return Object.assign({}, state, {
				primaryKycProof: action.kycProof
			})
		case SECONDARY_ + SAVE_KYC_PROOF: 
			return Object.assign({}, state, {
				secondaryKycProof: action.kycProof
			})
    	default:
			return state
	}
}

export default kycQuestions