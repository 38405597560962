import { HttpService, currencyServiceApi } from 'Scripts/Shared'
import { config } from 'Scripts/Shared/config'
import { fromJsonApi } from 'Scripts/Shared/jsonApi'

export default class CurrencyApiService {
    constructor() {
        this.serviceUrl = config.serviceUrls.CurrencyServiceUrl
        this.http = new HttpService()
    }

    getCountry = (id) => {
        const { url, includes } = currencyServiceApi.countries

        let options = {
            include: [includes.products],
            lang: currentCulture
        }

        return this.http.get(`${this.serviceUrl}${url}/${id}${this.http.createQuery(options)}`).then(response => fromJsonApi(response))
    }

    getPriceCategories = (countryCode) => {
        const { url, includes, filters } = currencyServiceApi.priceCategories

        let options = {
            include: [includes.fromSourceExchangeRates.sourceProduct.countries, includes.fromSourceExchangeRates.targetProduct.countries],
            filters: { [filters.countryCode]: [countryCode] },
            lang: currentCulture
        }

        return this.http.get(this.serviceUrl + url + this.http.createQuery(options)).then(response => fromJsonApi(response))
    }

    getExternalPickupPoint = (id) => {
        const { url } = currencyServiceApi.externalPickupPoints
        return this.http.get(`${this.serviceUrl}${url}/${id}`).then(response => fromJsonApi(response))
    }

    getCampaign(market, id) {
		const { url } = currencyServiceApi.CampaignCodes
		const campaignUrl = this.serviceUrl + url +'/'+ market + '/' + id
		return this.http.getWithAuthToken(campaignUrl)
			.then((response) => response.data)
	}

    getReservationDays(storeId) {
		try {
		 const { url } = currencyServiceApi.reservationDays
		 const queryURL = `${this.serviceUrl}${url}/${storeId}`
 
            return this.http.get(queryURL).then((response) => {return response})
            }
            catch (error) {
                return error
            }
        }


}