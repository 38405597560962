export default class SearchPagination {
    constructor(nrOfItemsToDisplay) {
        this.nrOfItemsToDisplay = nrOfItemsToDisplay
		this.nrOfPages = 0
		this.init()
	}

    get nrOfItemsToDisplay() {
        return this._nrOfItemsToDisplay
    }

    set nrOfItemsToDisplay(value) {
        this._nrOfItemsToDisplay = value
    }

    init() {
        this.paginateSearchResult
        this.addShowMoreButton
        this.removeShowMoreButton
        this.insertAfterElement
        this.countNrOfItems
        this.countNrOfItemsHidden
        this.countNrOfPages
        this.hideItems
        this.showMoreItems
        this.showItemsInitial
        this.updateItemsLeft
    }

    paginateSearchResult() {
        let searchResult = document.getElementById('js-search-result')

        if (searchResult) {

			let searchResultItems = searchResult.querySelectorAll('.js-search-result-item')
            let nrOfItems = searchResultItems.length

            if (nrOfItems > this.nrOfItemsToDisplay) {
                this.hideItems()
                this.showItemsInitial()
                let nrOfItemsLeft = this.countNrOfItemsHidden()
                this.addShowMoreButton(searchResult, nrOfItemsLeft)
            }

            let showMoreToggle = document.getElementById('js-show-more-search-results')

            if (showMoreToggle) {
                showMoreToggle.addEventListener('click', this.showMoreItems)
            }
        }
    }

    countNrOfItemsVisible() {
        let searchResult = document.getElementById('js-search-result')
		let searchResultItems = searchResult.querySelectorAll('.js-search-result-item.visible')

        return searchResultItems.length
    }

    countNrOfItemsHidden() {
        let searchResult = document.getElementById('js-search-result')
		let searchResultItems = searchResult.querySelectorAll('.js-search-result-item.hidden')

        return searchResultItems.length
    }

    countNrOfPages(nrOfItemsHidden) {
        return Math.ceil(nrOfItemsHidden / searchPagination.nrOfItemsToDisplay)
    }

    hideItems() {
        let searchResult = document.getElementById('js-search-result')
		let searchResultItems = searchResult.querySelectorAll('.js-search-result-item')

        searchResultItems.forEach(function(element) {
            element.classList.remove('visible')
        })
    }

    showItemsInitial() {
        let searchResult = document.getElementById('js-search-result')
		let searchResultItems = searchResult.querySelectorAll('.js-search-result-item')
        let nrOfItemsToDisplayInitial = searchPagination.nrOfItemsToDisplay

        searchResultItems.forEach(function(searchItem, index) {

            if (index < nrOfItemsToDisplayInitial) {
                searchItem.classList.add('visible')
            }
            else {
                searchItem.classList.add('hidden')
            }
        })
    }

    addShowMoreButton(searchResult, nrOfresultsLeft) {
        let searchShowMoreButton = document.getElementById('js-show-more-search-results')

        if (searchShowMoreButton) {
            searchPagination.removeShowMoreButton()
        }

        let buttonText = resources.Search_show_more ? resources.Search_show_more : ''

        let buttonMarkup = '<a class="button button--primary button--cta" tabindex="0" id="js-show-more-search-results"><span>'
            + buttonText + ' (' + nrOfresultsLeft + ')</span></a>'

        var button = document.createElement('div')
        button.innerHTML = buttonMarkup

        this.insertAfterElement(button, searchResult)
    }

    removeShowMoreButton() {
        let searchShowMoreButton = document.getElementById('js-show-more-search-results')

        if (searchShowMoreButton) {
            searchShowMoreButton.remove()
        }
    }

    insertAfterElement(el, referenceNode) {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
    }

    showMoreItems() {
        let searchResult = document.getElementById('js-search-result')
		let searchResultItems = searchResult.querySelectorAll('.js-search-result-item.hidden')
        let nrOfItemsToShow = searchPagination.nrOfItemsToDisplay

        searchResultItems.forEach(function(searchItem, index) {
            if (index < nrOfItemsToShow) {
                searchItem.classList.remove('hidden')
                searchItem.classList.add('visible')
            }
        })

        let nrOfItemsLeft = searchPagination.countNrOfItemsHidden()
        let nrOfPages = searchPagination.countNrOfPages(nrOfItemsLeft)

        searchPagination.updateItemsLeft(nrOfItemsLeft)

        if (nrOfPages < 1) {
            searchPagination.removeShowMoreButton()
        }
    }

    updateItemsLeft(itemsLeft) {
        let searchShowMoreButton = document.getElementById('js-show-more-search-results').querySelector('span')

        if (searchShowMoreButton) {
            let buttonText = resources.Search_show_more ? resources.Search_show_more : ''

            searchShowMoreButton.innerText = buttonText + ' (' + itemsLeft + ')'
        }
    }
}