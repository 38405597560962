export const status = {
	minimized: true, 
	active: false, 
	done: false,
	disabled: true
}

export const state = {
	parentStep: null, 
	choosenStep: null, 
	availableSteps: []
}

export const initialFormData = {
	value:'', 
	isValid: false, 
	isTouched: false
}
