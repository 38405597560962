import React from 'react'

const SearchListComponent = (props) => {
	const searchUrl = props.searchPage + '?query='

	const list = props.results.map((searchItem, index) => (
		<li key={index} className='search-suggestions__item'>
			<a href={searchUrl + searchItem.suggestion.query}>{searchItem.suggestion.query}</a>
		</li>
	))

	return <ul className={`search-suggestions__list-of-items ${props.results.length > 0 ? 'display' : ''}`}>{list}</ul>
}

export default SearchListComponent