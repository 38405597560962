export * from 'Scripts/CommonComponents/ApplicationForm/Constants/action-types'

// Add actions specific to loan here.

export const INIT_LOAN_CALCULATOR = 'INIT_LOAN_CALCULATOR'
export const TOGGLE_LOAN_CALCULATOR = 'TOGGLE_LOAN_CALCULATOR'
export const UPDATE_LOAN_CALCULATOR = 'UPDATE_LOAN_CALCULATOR'
export const LOAN_CALCULATOR_DONE = 'LOAN_CALCULATOR_DONE'
export const RESET_LOAN_CALCULATOR = 'RESET_LOAN_CALCULATOR'

export const PURPOSE_AND_RESOLVE_LOANS_ = 'PURPOSE_AND_RESOLVE_LOANS_'

export const _MARITAL_STATUS = '_MARITAL_STATUS'
export const _EMPLOYMENT_STATUS = '_EMPLOYMENT_STATUS'
export const _ACCOUNT_PURPOSE = '_ACCOUNT_PURPOSE'
//export const _ASSETS_AND_DEBTS = '_ASSETS_AND_DEBTS'
export const _RESOLVE_LOANS = '_RESOLVE_LOANS'
export const _BANK_ACCOUNT_INFORMATION = '_BANK_ACCOUNT_INFORMATION'
