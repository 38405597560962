export const NEXT_STEP = 'NEXT_STEP'
export const RESET_TO_STEP = 'RESET_TO_STEP'
export const INIT_STEPS = 'INIT_STEPS'
export const RESET_ALL = 'RESET_ALL'

export const INIT = 'INIT'
export const TOGGLE = 'TOGGLE'
export const INIT_FORM = 'INIT_FORM'
export const DONE = 'DONE'
export const UPDATE = 'UPDATE'
export const RESET = 'RESET'
export const DISABLE = 'DISABLE'

export const _PEP_QUESTIONS = '_PEP_QUESTIONS'

export const INIT_MAIN_INFORMATION = 'INIT_MAIN_INFORMATION'
export const TOGGLE_MAIN_INFORMATION = 'TOGGLE_MAIN_INFORMATION'
export const UPDATE_MAIN_INFORMATION = 'UPDATE_MAIN_INFORMATION'
export const MAIN_INFORMATION_DONE = 'MAIN_INFORMATION_DONE'
export const RESET_MAIN_INFORMATION = 'RESET_MAIN_INFORMATION'

export const INIT_PERSONAL_INFORMATION = 'INIT_PERSONAL_INFORMATION'
export const TOGGLE_PERSONAL_INFORMATION = 'TOGGLE_PERSONAL_INFORMATION'
export const PERSONAL_INFORMATION_DONE = 'PERSONAL_INFORMATION_DONE'
export const UPDATE_PERSONAL_INFORMATION = 'UPDATE_PERSONAL_INFORMATION'
export const RESET_PERSONAL_INFORMATION = 'RESET_PERSONAL_INFORMATION'
export const PERSONAL_INFORMATION_HAS_AGREED_TO_MARKETING = 'PERSONAL_INFORMATION_HAS_AGREED_TO_MARKETING'

export const INIT_ACCOUNT_QUESTIONS = 'INIT_ACCOUNT_QUESTIONS'
export const TOGGLE_ACCOUNT_QUESTIONS = 'TOGGLE_ACCOUNT_QUESTIONS'
export const ACCOUNT_QUESTIONS_DONE = 'ACCOUNT_QUESTIONS_DONE'
export const INIT_ACCOUNT_QUESTIONS_FORM = 'INIT_ACCOUNT_QUESTIONS_FORM'
export const UPDATE_ACCOUNT_QUESTIONS = 'UPDATE_ACCOUNT_QUESTIONS'
export const RESET_ACCOUNT_QUESTIONS = 'RESET_ACCOUNT_QUESTIONS'

export const INIT_TAX_QUESTIONS = 'INIT_TAX_QUESTIONS'
export const INIT_TAX_QUESTIONS_FORM = 'INIT_TAX_QUESTIONS_FORM'
export const TOGGLE_TAX_QUESTIONS = 'TOGGLE_TAX_QUESTIONS' 
export const UPDATE_TAX_QUESTIONS_FORM = 'UPDATE_TAX_QUESTIONS_FORM'
export const TAX_QUESTIONS_DONE = 'TAX_QUESTIONS_DONE'
export const RESET_TAX_QUESTIONS = 'RESET_TAX_QUESTIONS'

export const INIT_CONFIRMATION = 'INIT_CONFIRMATION'
export const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION' 
export const CONFIRMATION_DONE = 'CONFIRMATION_DONE'
export const RESET_CONFIRMATION = 'RESET_CONFIRMATION'

export const UPDATE_SECONDARY_APPLICANT = 'UPDATE_SECONDARY_APPLICANT'

export const PRIMARY_ = 'PRIMARY_'
export const SECONDARY_ = 'SECONDARY_'

export const SET_EXIT = 'SET_EXIT'
export const RESET_EXIT = 'RESET_EXIT'