import { hot } from 'react-hot-loader/root'
import React, { lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { rootReducer } from './Reducers'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import {initNotificationMessage} from 'Scripts/CommonComponents/NotificationMessageComponent'

const persistConfig = {
	key: 'private_account_root',
	storage: storageSession,
	stateReconciler: hardSet,
	blacklist: ['exits']
}

const PrivateAccountContainer = lazy(() => import('./Containers/PrivateAccountContainer'))
const HotPrivateAccountContainer = hot(PrivateAccountContainer)

export function initPrivateAccountApp() {
	const privateAccountApp = document.getElementById('privateAccountApp')

	if (privateAccountApp !== null) {
		persistConfig.key = privateAccountViewModel.AccountApplicationType + '_account_root'
		const persistedReducer = persistReducer(persistConfig, rootReducer)
		const store = createStore(persistedReducer, 
			window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
		const persistor = persistStore(store)

		initNotificationMessage(store)

		ReactDOM.render(
			<Provider store={store}>
				<PersistGate loading={<div>
					Loading {privateAccountViewModel.AccountApplicationType} account application...
					</div>} persistor={persistor}>
					<HotPrivateAccountContainer 
						privateAccountViewModel={privateAccountViewModel}
					/>
				</PersistGate>
			</Provider>,
			privateAccountApp
		)
	}
}