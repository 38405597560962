const MODAL_SCROLL_LOCK_CLASS = 'body-scroll-lock'

export const lockPageScroll = () => {
	document.body.classList.add(MODAL_SCROLL_LOCK_CLASS)
}

export const unlockPageScroll = () => {
    document.body.classList.remove(MODAL_SCROLL_LOCK_CLASS)
}

export const togglePageScroll = () => {
    document.body.classList.toggle(MODAL_SCROLL_LOCK_CLASS)
}