import CurrencyApiService from 'Scripts/Shared/services/currencyApiService'

export class DestinationService {
	constructor() {
		this.currencyApiService = new CurrencyApiService()
	}

	getPriceCategories = (countryCode) => this.currencyApiService.getPriceCategories(countryCode)

	getCountry = (id) => this.currencyApiService.getCountry(id)
}