import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const LoanOfferComponent = lazy(() => import('./Components/LoanOfferComponent'))
const HotLoanOfferComponent = hot(LoanOfferComponent)

export function initLoanOfferApp() {
	const loanOfferApp = document.getElementById('loanOfferApp')

	if (loanOfferApp !== null) {
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
				<HotLoanOfferComponent loanOfferViewModel={loanOfferViewModel} />
			</Suspense>, loanOfferApp)
	}
}