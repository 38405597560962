import { HttpService, customerServiceApi } from 'Scripts/Shared'
import { config } from 'Scripts/Shared/config'
import { toJsonApi } from 'Scripts/Shared/jsonApi'

export default class CustomerApiService {
	constructor() {
		this.customerServiceUrl = config.serviceUrls.CustomerServiceUrl
		this.http = new HttpService()
	}

	getCampaignById(accessToken, id) {
		const { url } = customerServiceApi.campaigns
		const customerUrl = this.customerServiceUrl + url + '/' + id
		return this.http.getWithAuthToken(customerUrl, accessToken)
			.then((response) => response.data.attributes)
	}

	getCustomer(accessToken) {
		const { url } = customerServiceApi.customer
		const customerUrl = this.customerServiceUrl + url
		return this.http.getWithAuthToken(customerUrl, accessToken)
			.then((response) => response.data.attributes)
	}

	createCustomer(accessToken, command) {
		const { url } = customerServiceApi.create
		const customerUrl = this.customerServiceUrl + url
		return this.http.postWithAuthToken(customerUrl, toJsonApi(command), accessToken)
			.then((response) => response.data.attributes)
	}

	getCustomerInfo(accessToken) {
		const { url } = customerServiceApi.info
		const customerUrl = this.customerServiceUrl + url
		return this.http.getWithAuthToken(customerUrl, accessToken)
	}

	getCustomerKyc(accessToken) {
		const { url } = customerServiceApi.kyc
		const customerKycUrl = this.customerServiceUrl + url
			+ `?productType=${'CounterService'}&language=${currentCulture}&market=${currentMarket}`

		return this.http.getWithAuthToken(customerKycUrl, accessToken)
	}

	getCustomerKycByProductType(accessToken, productType) {
		const { url } = customerServiceApi.kyc
		const customerKycUrl = this.customerServiceUrl + url
			+ `?productType=${productType}&language=${currentCulture}&market=${currentMarket}`

		return this.http.getWithAuthToken(customerKycUrl, accessToken)
	}

	postCustomerKyc(kycForms, accessToken) {
		const { url } = customerServiceApi.kyc
		const customerKycUrl = this.customerServiceUrl + url
		return this.http.postWithAuthToken(customerKycUrl, toJsonApi(kycForms.forms), accessToken)
	}

	postCustomerCitizenship(citizenshipForm, accessToken) {
		const { url } = customerServiceApi.citizenship
		const customerCitizenshipUrl = this.customerServiceUrl + url
		return this.http.putWithAuthToken(customerCitizenshipUrl, toJsonApi(citizenshipForm), accessToken)
	}

	getCustomerConsent(accessToken) {
		const { url } = customerServiceApi.consents
		const consentUrl = this.customerServiceUrl + url
		return this.http.getWithAuthToken(consentUrl, accessToken)
			.then((response) => response.data.attributes)
	}

	validateCustomerCrc(taxQuestionsMappedValues, accessToken) {
		const { url } = customerServiceApi.crs
		const customerCrcUrl = this.customerServiceUrl + url
		return this.http.postWithAuthToken(customerCrcUrl, taxQuestionsMappedValues, accessToken)
	}

	updateCustomerConsent(accessToken, consent) {
		const { url } = customerServiceApi.consents
		const consentUrl = this.customerServiceUrl + url
		return this.http.putWithAuthToken(consentUrl, toJsonApi(consent), accessToken)
			.then((response) => response.data.attributes)
	}

	updateCustomerContactInfo(accessToken, contactInfo) {
		const { url } = customerServiceApi.contactDetails
		const customerUrl = this.customerServiceUrl + url
		return this.http.patchWithAuthToken(customerUrl, toJsonApi(contactInfo), accessToken)
			.then((response) => response.data.attributes)
	}

	activateOffer(accessToken, id) {
		const customerUrl = this.customerServiceUrl + 'Campaigns/' + id + '/_activate'
		return this.http.postWithAuthToken(customerUrl, null, accessToken)
			.then((response) => response.data.attributes)
	}

	sendVerificationEmail(accessToken, command, id) {
		const customerUrl = this.customerServiceUrl + 'Campaigns/' + id + '/_sendVerification'
		return this.http.postWithAuthToken(customerUrl, command, accessToken)
			.then((response) => response == null)
	}

	getCountries() {
		const { url } = customerServiceApi.countries
		const countriesUrl = this.customerServiceUrl + url
		return this.http.get(countriesUrl)
	}

	activateCampaignOffer(campaignId) {
		const { url } = customerServiceApi.campaigns
		const campaignUrl = this.customerServiceUrl + url + '/' + campaignId + '/_activate'
		return this.http.post(campaignUrl)
	}

	getCustomerCampaign(accessToken) {
		const { url } = customerServiceApi.campaigns
		const campaignUrl = this.customerServiceUrl + url
		return this.http.getWithAuthToken(campaignUrl, accessToken)
			.then((response) => response.data)
	}

	uploadFilesByOrderId(id, files) {
		const { url } = customerServiceApi.customerInformation;
		const uploadUrl = this.customerServiceUrl + url + '/upload/' + id;
	  
		const formData = new FormData();

		files.forEach((file) => {
			let newFile = file;

			newFile = new File([file], file.name.replace('.jpeg.', '.jpg'), { type: file.type });

			formData.append('file', newFile)
		});
	  
		fetch(uploadUrl, {
			method: 'POST',
			body: formData,
		})
		.then(response => {
			if (!response.ok) {
			  throw new Error('Network response was not ok');
			}
			return response
		  })
		  .then(data => {
			console.log('Post created successfully:', data);
		  })
		  .catch(error => {
			console.error('There was a problem with the POST request:', error);
		  });
	  }
	getTransactionsForms() {
		const { url } = customerServiceApi.transactionForms
		const transactionsUrl = `${this.customerServiceUrl + url}?language=${currentCulture}&market=${currentMarket}`
		return this.http.get(transactionsUrl)
	}

	validateTransactionsForms(transactionForms, accessToken) {
		const { url } = customerServiceApi.validateTransactionAnswers
		const transactionsUrl = this.customerServiceUrl + url
		return this.http.postWithAuthToken(transactionsUrl, toJsonApi([transactionForms]), accessToken)
	}
}