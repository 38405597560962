import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const theme = createTheme({
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'none',
				padding: '0',
				padding: '5px 0 5px 0',

				hoverBackground: 'none',
				hoverTextDecoration: 'underline',
				verticalAlign: 'baseline',
				minWidth: 0,
			},
			label: {
				display: 'block',
			},
			text: {
				padding: '0 !important',
				margin: '0',
				top: '0',
				left: '0',
				margin: '0',
				lineHeight: '1.36',
				borderRadius: '0',
				verticalAlign: 'baseline',
			},
		},
		MuiMenuItem: {
			root: {
				color: '#18161F',
				padding: '0',
				paddingLeft: '0',
				paddingRight: '0',
				height: 'auto',
				boxSizing: 'border-box',
				fontSize: '14px',
				fontFamily: [
					'Muli-Regular',
					'sans-serif'
				].join(','),
			},
		},
		MuiListItem: {
			root: {
				paddingLeft: '0',
				paddingRight: '0',
			},
			gutter: {
				paddingLeft: '0',
				paddingRight: '0',
			},
		},
		MuiModal: {
			root: {
				top: 'auto',
				left: 'auto',
			},
		},
		MuiPaper: {
			root: {
				borderRadius: '0',
				top: 'unset',
				left: 'unset',
			},
			rounded: {
				borderRadius: '0',
				top: 'unset',
				left: 'unset',
			},
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Muli-Regular',
			'sans-serif'
		].join(','),
		fontSize: '14px',
		fontSize: '1rem',
		fontWeight: 'normal',
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
			disableTouchRipple: true,
		},
		MuiPaper: {
			Class: 'login-menu-wrapper',
		},
		MuiList: {
			Class: 'login-menu',
		},
	},
})

const styles = theme => ({
	margin: {
		margin: '0',
		padding: '3px 5px 6px 5px',
	},
	cssRoot: {
		minWidth: '0',
		minHeight: '0',
		lineHeight: 'inherit',
		fontSize: '14px',
		fontSize: '1rem',
		fontWeight: 'normal',
		color: '#18161F',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'underline',
		},
	},
})

class LoginToggleComponent extends Component {
	constructor(props) {
		super(props)

		this.state = {
			anchorEl: null,
		}

		this.handleClick = event => {
			this.setState({ anchorEl: event.currentTarget })
			this.setState({ open: true })
		}

		this.handleClickInside = event => {
			if (event.currentTarget.getAttribute('data-url').includes('forexkreditkort')) {
				window.open(event.currentTarget.getAttribute('data-url'), '_blank')
			} else {
				window.open(event.currentTarget.getAttribute('data-url'), '_self')
			}
			this.setState({ anchorEl: null })
		}

		this.handleClose = () => {
			this.setState({ anchorEl: null })
			this.setState({ open: false })
		}
	}

	render() {

		const { classes } = this.props
		const { anchorEl } = this.state

		let buttonClasses = classNames({
			'header-button': true,
			'header-button--login-button': true
		})

		return (
			<MuiThemeProvider theme={theme}>
				<Button
					className={classNames(buttonClasses)}
					aria-owns={anchorEl ? 'login-menu' : null}
					aria-haspopup='true'
					onClick={this.handleClick}
					role={window.innerWidth > 1023 ? 'menuitem' : null}
				>

					<span className="header-button__icon icon icon--login-icon"></span>
					<span className="header-button__text">{this.props.signInLabel}</span>
				</Button>

				<Menu
					className='login-menu-overlay'
					id='login-menu'
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
				>

					{this.props.accountLoginLinkItems.map((loginItem, index) =>
						<MenuItem key={index} className='login-menu__item' onClick={this.handleClickInside} data-url={loginItem.Url}>
							<span className={`icon icon--quicklink ${loginItem.IconName}`}></span>
							<span className='text'>
								<span className='heading'>{loginItem.Heading}</span>
								<span className='sub-heading'>{loginItem.SubHeading}</span>
							</span>
							<span className='icon icon--chevron-right'></span>
						</MenuItem>
					)}
				</Menu>
			</MuiThemeProvider>
		)
	}
}

LoginToggleComponent.propTypes = {
	displayLogin: PropTypes.bool,
	signInLabel: PropTypes.string,
	accountLoginLinkItems: PropTypes.arrayOf(PropTypes.shape({
		heading: PropTypes.string,
		subHeading: PropTypes.string,
		url: PropTypes.string,
		iconName: PropTypes.string
	})),
}

export default withStyles(styles)(LoginToggleComponent)