import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import SearchFormComponent from './Components/SearchFormComponent'
import MediaQuery from 'react-responsive'

const SearchBar = (searchValue) => {

	if (searchViewModel.SearchServiceUrl == null) {
		searchViewModel.SearchServiceUrl = ''
	}

	let searchFormComponent = <SearchFormComponent
		searchPage={searchViewModel.SearchPageUrl}
		searchValue={searchValue.searchValue}
		searchServiceUrl={searchViewModel.SearchServiceUrl}
	/>
	const searchBarComponentOpen = document.getElementById('searchBarOpen')

	if (searchBarComponentOpen) {
		searchBarComponentOpen.classList.add('header__sticky-search-container')
		return ReactDOM.createPortal(searchFormComponent, searchBarComponentOpen)
	}

	return <MediaQuery minWidth={1024}>
		{(matches) => {
			if (matches) {
				let searchBarComponentDesktop = document.getElementById('searchBarDesktop')
				if (searchBarComponentDesktop) {
					return ReactDOM.createPortal(searchFormComponent, searchBarComponentDesktop)
				}
				else return null
			}
			else {
				let searchBarComponentMobile = document.getElementById('searchBarMobile')
				if (searchBarComponentMobile) {
					return ReactDOM.createPortal(searchFormComponent, searchBarComponentMobile)
				}
				else return null
			}
		}
		}
	</MediaQuery>
}

const HotSearchBar = hot(SearchBar)

export function initSearchBarComponent() {
	let searchBar = document.getElementById('searchBar')
	let searchBarMobile = document.getElementById('searchBarMobile')
	let searchBarOpen = document.getElementById('searchBarOpen')

	let query = ''

	if (typeof searchPageViewModel !== 'undefined') {
		query = searchPageViewModel.Query
	}

	if (searchBar !== null || searchBarMobile !== null || searchBarOpen !== null) {

		ReactDOM.render(
			<HotSearchBar
				searchValue={query}
			/>,
			searchBar
		)
	}
}