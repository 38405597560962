import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import LoanService from 'Scripts/CommonComponents/LoanCalculatorComponent/LoanService'
import { trackErrorEvent } from 'Scripts/Shared/analyticsEventTracking'

const LoanCalculatorContainerComponent = lazy(() => import('./Components/LoanCalculatorContainerComponent'))
const HotLoanCalculatorContainerComponent = hot(LoanCalculatorContainerComponent)

export function initLoanCalculatorApp() {
	const loanCalculatorApps = document.getElementsByClassName('loan-calculator-block__app')

	Array.from(loanCalculatorApps).forEach((elem) => {
		let model = elem.getAttribute('data-view-model')
		if (!model) {
			return
		}
		model = JSON.parse(model)

		let loanCalculatorService = new LoanService(model.LoanServiceUrl)
		loanCalculatorService.getFormValues().then((loanFormValues) => {
			ReactDOM.render(
				<Suspense fallback={<div></div>}>
				<HotLoanCalculatorContainerComponent
					interest={model.Interest}
					loanAmountMin={loanFormValues.amounts[0]}
					loanAmountMax={loanFormValues.amounts[loanFormValues.amounts.length - 1]}
					loanAmountDefault={model.LoanAmountDefault}
					loanAmountStep={loanFormValues.amounts[1] - loanFormValues.amounts[0]}
					paybackYearsMin={loanFormValues.repaymentYears[0]}
					paybackYearsMax={loanFormValues.repaymentYears[loanFormValues.repaymentYears.length - 1]}
					paybackYearsDefault={model.PaybackYearsDefault}
					paybackYearsStep={loanFormValues.repaymentYears[1] - loanFormValues.repaymentYears[0]}
					informationBoxHeading={model.InformationBoxHeading}
					ctaLinkUrl={model.CtaLinkUrl}
					ctaLinkLabel={model.CtaLinkLabel}
					calculationInformationHtmlString={model.CalculationInformationHtmlString}
					explanationLinkLabel={model.ExplanationLinkLabel}
					currencyCode={model.CurrencyCode}
					error={false}
					/>
				</Suspense>,
				elem
			)
		}).catch((err) => {
			ReactDOM.render(
				<Suspense fallback={<div></div>}>
				<HotLoanCalculatorContainerComponent
					interest={0}
					loanAmountMin={0}
					loanAmountMax={0}
					loanAmountDefault={0}
					loanAmountStep={0}
					paybackYearsMin={0}
					paybackYearsMax={0}
					paybackYearsDefault={0}
					paybackYearsStep={0}
					informationBoxHeading={''}
					ctaLinkUrl={''}
					ctaLinkLabel={''}
					calculationInformationHtmlString={''}
					explanationLinkLabel={''}
					currencyCode={''}
					error={true}
					/>
				</Suspense>,
				elem
			)

			trackErrorEvent(err, 'loanCalculatorServiceError', window.location.pathname)
		})

	})
}