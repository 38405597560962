import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const FileUploadContainer = lazy(() => import('./Components/FileUploadPage'))
const HotFileUploadContainer = hot(FileUploadContainer)

export function initCustomerFileUploadContainer() {
    let fileUploadApp = document.getElementById('customer-file-upload')
    if (fileUploadApp) {
        const viewModel = customerFileUploadPageModel || {}
        ReactDOM.render(
            <Suspense fallback={<div></div>}>
                <HotFileUploadContainer 
                fileUploadPage={viewModel} 
                />
            </Suspense>,
            fileUploadApp
        )

    }
}	