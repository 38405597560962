import { unlockPageScroll, togglePageScroll } from 'Scripts/Helpers/Modals'

export function initMobileNavigation() {
	let mobileMenuButton = document.getElementById('js-toggle-mobile-menu')
	let bodyTag = document.getElementsByTagName('body')
	let siteWrapper = document.getElementById('site-wrapper')
	let toggleSubMenuButtons = document.getElementsByClassName('js-open-sub-menu')
	let toggleLanguageSubMenu = document.getElementById('js-open-lang-sub-menu')
	let closeMenuLink = document.getElementById('js-close-mobile-menu')

	if (mobileMenuButton) mobileMenuButton.addEventListener('click', toggleMenu)
	if (closeMenuLink) closeMenuLink.addEventListener('click', closeMenu)

	if (toggleLanguageSubMenu) {
		toggleLanguageSubMenu.addEventListener('click', toggleLangSubMenu)
	}

	for (let subMenu of toggleSubMenuButtons) {
		subMenu.addEventListener('click', toggleSubMenu)
	}

	function toggleMenu() {
		togglePageScroll()
		document.getElementById('site').classList.toggle('mobile-menu-is-open')
		document.getElementById('header').classList.toggle('fixed-position')
		document.getElementById('js-toggle-mobile-menu').classList.toggle('menu--is-open')
		document.getElementById('main-menu-items').classList.toggle('is-open')
		bodyTag[0].addEventListener('click', clickOutsideMenu, true)
	}

	function closeMenu() {
		unlockPageScroll()
		document.getElementById('site').classList.remove('mobile-menu-is-open')
		document.getElementById('header').classList.remove('fixed-position')
		document.getElementById('js-toggle-mobile-menu').classList.remove('menu--is-open')
		document.getElementById('main-menu-items').classList.remove('is-open')
		bodyTag[0].removeEventListener('click', clickOutsideMenu, true)
	}

	function toggleSubMenu() {
		let subMenu = this.closest('div').nextElementSibling
		let subMenuMenuItem = this.closest('li')
		subMenu.classList.toggle('show-children')
		this.classList.toggle('sub-menu-open')

		if (this.hasAttribute('aria-expanded')) {
			this.removeAttribute('aria-expanded')
		}
		else {
			this.setAttribute('aria-expanded', 'true')
		}

		if (subMenuMenuItem.classList.contains('active')) {
			subMenuMenuItem.classList.remove('active')
			subMenuMenuItem.classList.remove('menu-item--sub-menu-open')
		}
		else {
			subMenuMenuItem.classList.toggle('menu-item--sub-menu-open')
		}
	}

	function toggleLangSubMenu() {
		let langSubMenu = this.closest('ul')
		langSubMenu.classList.toggle('show-children')
		this.classList.toggle('sub-menu-open')
	}

	function clickOutsideMenu(e) {
		if (e.target == siteWrapper) {
			closeMenu()
			bodyTag[0].removeEventListener('click', clickOutsideMenu, true)
		}
	}
}