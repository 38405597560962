import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const CollectConsentContainer = lazy(() => import('./CollectConsentContainer'))
const HotCollectConsentContainer = hot(CollectConsentContainer)

export function initCollectConsentContainer() {
    let collectConsentApp = document.getElementById('collectConsent')
    if (collectConsentApp) {
        const viewModel = collectConsentViewModel || {}
        ReactDOM.render(
            <Suspense fallback={<div></div>}>
                <HotCollectConsentContainer offerPage={viewModel} />
            </Suspense>,
            collectConsentApp
        )

    }
}	