import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

const CurrencyConverterComponent = lazy(() => import('./Components/CurrencyConverterComponent')) 

const HeroConverterIndex = ({currencyInfo}) => {
	const [isSummaryOpen, setIsSummaryOpen] = useState(false)
	const [isMobile, setIsMobile] = useState(window.screen.width < 900)

	useEffect(() => {
		function handleResize(){
			setIsMobile(window.screen.width < 900  )	
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		  
	  }  
  
	}, [])

   	const imgClasses = `${isSummaryOpen && isMobile ? 'hero-converter-block__mobileImg': ''}`
	const background = 
		isMobile && currencyInfo.MobileImageUrl ? 
			`url('${currencyInfo.MobileImageUrl}?w=${window.screen.width}&quality=100')`
		:  currencyInfo.DesktopImageUrl ? 
			`url('${currencyInfo.DesktopImageUrl}?w=${window.screen.width}&quality=100')` 
		: '#ffcf00'

	return (
		<div className={`hero-converter-block hero-area__content--align ${imgClasses}`}
			style={{
				background,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				display: 'flex',
			}}
		>
			<Suspense fallback={<div></div>}>
				<CurrencyConverterComponent
					isSummaryOpen={isSummaryOpen}
					setIsSummaryOpen={setIsSummaryOpen}
					currencyTitle={currencyInfo.Title}
					baseCountryCode={currencyInfo.BaseCountryCode}
					selectedCurrency={currencyInfo.SelectedCurrency ?
					currencyInfo.SelectedCurrency :
					currencyInfo.BaseTargetCurrencyCode}
					basicCurrency={currencyInfo.BaseTargetCurrencyCode}
					selectedDestination={currencyInfo.SelectedDestination}
					basketPageUrl={currencyInfo.BasketPageUrl}
					findStorePageUrl={currencyInfo.FindStorePageUrl}
					customerCarePageUrl={currencyInfo.CustomerCarePageUrl}
					initiallySelling={false}
					isOnCurrencyConverterPage={true}
					currencyBuybackPageUrl={currencyInfo.CurrencyBuybackPageUrl}
					currencyConverterPageUrl={currencyInfo.CurrencyConverterPageUrl}
					fromAmount={currencyInfo.FromAmount}
					toAmount={currencyInfo.ToAmount}
					currencyServiceUrl={currencyInfo.CurrencyServiceUrl}
					saveToBasket={true}
					showPriceEstimates={currencyInfo.ShowPriceEstimates}
					showCashIndex={currencyInfo.ShowCashIndex}
					currencyIngress={currencyInfo.Ingress}
					uniqueSellingPoints={currencyInfo.UniqueSellingPoints}
				/>
			</Suspense>
		</div>
	)
}

const HotHeroConverterIndex = hot(HeroConverterIndex)

export function initHeroConverrterBlockComponent() {
	const currencyConverter = document.getElementById('hero-currency-converter')


	if (currencyConverter !== null) {
		let currencyInfo = heroConverterModel

		ReactDOM.render(
				<HotHeroConverterIndex currencyInfo={currencyInfo} />
			,
			currencyConverter
		)
	}
}