import { states } from './states'
let instance = null

export default class TransitionsSingleton {
	constructor(){
	  if(!instance){
		instance = this
	  }

	  this.steps = {
		[states.start]:{childStates:[states.loanCalculator]},
		[states.loanCalculator]:{childStates:[states.primaryInformation]},
		[states.primaryInformation]:{childStates:[states.personalInformation]},
		[states.personalInformation]:{childStates:[states.maritalStatus]},
		[states.maritalStatus]:{childStates:[states.employmentStatus]},
		[states.employmentStatus]:{childStates:[states.pepQuestions]},
		[states.pepQuestions]:{childStates:[states.secondaryInformation]},
		[states.secondaryInformation]:{childStates:[states.purposeAndResolveLoans, states.personalInformationCoApplicant]},
		[states.personalInformationCoApplicant]:{childStates:[states.maritalStatusCoApplicant]},
		[states.maritalStatusCoApplicant]:{childStates:[states.employmentStatusCoApplicant]},
		[states.employmentStatusCoApplicant]:{childStates:[states.pepQuestionsCoApplicant]},
		[states.pepQuestionsCoApplicant]:{childStates:[states.purposeAndResolveLoans]},
		[states.purposeAndResolveLoans]: {childStates:[states.accountPurpose]},
		//[states.assetsAndDebts]:{childStates:[states.accountPurpose]},
		[states.accountPurpose]:{childStates:[states.resolveLoans]},
		[states.resolveLoans]:{childStates:[states.bankAccountInformation]},
		[states.bankAccountInformation]: { childStates: [states.confirmation]},
		[states.confirmation]: { childStates: [states.loanOffer] },
		[states.loanOffer]:{childStates:[states.sign]}
	  }

	  return instance
	}

	setSteps(steps) {
		this.steps = steps
	}

	getAvailableStates(current) {
		return this.steps[current].childStates.concat()
	}

	getMetaInformation(current) {
		return this.steps ? this.steps[current].metaInformation : {}
	}
}

export const getMetaInformation = (current) => {
	let transitions = new TransitionsSingleton()
	return transitions.getMetaInformation(current)
}

export const getAvailableStates = (current) => {
	let transitions = new TransitionsSingleton()
	return transitions.getAvailableStates(current)
}
