import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const PopularCurrenciesComponent = lazy(() => import('./Components/PopularCurrenciesComponent'))

const HotPopularCurrenciesComponent = hot(PopularCurrenciesComponent)

export function initPopularCurrenciesComponent() {
	let popularCurrenciesApps = document.getElementsByClassName('popularCurrencies')

	if (popularCurrenciesApps !== undefined) {
		for (var item of popularCurrenciesApps) {
			ReactDOM.render(
				<Suspense fallback={<div></div>}>
					<HotPopularCurrenciesComponent
						baseCountryCode={popularCurrenciesModel.BaseCountryCode}
						currencyServiceUrl={popularCurrenciesModel.CurrencyServiceUrl}
						currencyConverterPageUrl={popularCurrenciesModel.CurrencyConverterPageUrl}
						tableTitle={popularCurrenciesModel.Title}
						exchangeAmount={popularCurrenciesModel.ExchangeAmount}
						/>
				</Suspense>,
				item
			)
		}
	}
}