export default class SessionStorageHelper {
	get(key){
		let item = sessionStorage.getItem(key)
		return JSON.parse(item)
	}

	set(key, value) {
		let jsonObject = JSON.stringify(value)
		sessionStorage.setItem(key, jsonObject)
	}

	delete(key) {
		sessionStorage.removeItem(key)
	}
}