import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const RelatedInfoComponent = lazy(() => import('../../CurrencyConverter/Scripts/Components/RelatedInfoComponent'))
const BankCurrencyConverterComponent = lazy(() => import('./Components/BankCurrencyConverterComponent'))

const HotBankCurrencyConverterComponent = hot(BankCurrencyConverterComponent)
const HotRelatedInfoComponent = hot(RelatedInfoComponent)

export function initBankCurrencyConverterComponent() {
	const bankCurrencyConverter = document.getElementById('bank-currency-converter')
	const relatedInfo = document.getElementById('related-info')

	if (bankCurrencyConverter !== null) {
		let currencyInfo = currencyConverterModel
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
				<HotBankCurrencyConverterComponent baseCountryCode={currencyInfo.BaseCountryCode}
					selectedCurrency={currencyInfo.SelectedCurrency ? 
						currencyInfo.SelectedCurrency : 
						currencyInfo.BaseTargetCurrencyCode}
					selectedDestination={currencyInfo.SelectedDestination}
					initiallySelling={currencyInfo.InitiallySelling}
					fromAmount={currencyInfo.FromAmount}
					currencyServiceUrl={currencyInfo.CurrencyServiceUrl}
					cardServiceUrl = {currencyInfo.CardServiceUrl}
				/>
			</Suspense>,
			bankCurrencyConverter
		)
	}

	if (relatedInfo !== null) {
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
				<HotRelatedInfoComponent
					teaserHeading={relatedInfoModel.TeaserHeading}
					teaserBody={relatedInfoModel.TeaserBody}
				/>
			</Suspense>, 
			relatedInfo
		)
	}
}
