import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { DestinationService } from './Services/DestinationService'
import { get, isEmpty } from 'lodash'
import { trackErrorEvent } from 'Scripts/Shared/analyticsEventTracking'

const ErrorComponent = () => (
	<div className='info-message info-message--message-error'>
		<div className='error-container'>
			<span className='icon-exclamation-triangle'></span>
			<span className='error-message'>{resources.Something_went_wrong || ''}</span>
		</div>
	</div>
)

const renderErrorComponent = (htmlElement) => ReactDOM.render(<ErrorComponent />, htmlElement)

const DestinationCurrencyComponent = lazy(() => import('./Components/DestinationCurrencyComponent'))
const DestinationCurrencyPriceIndexInfoComponent = lazy(() => import('./Components/DestinationCurrencyPriceIndexInfoComponent'))
const ConversionListComponent = lazy(() => import('../../../CurrencyConverter/Scripts/Components/ConversionListComponent'))
const DestinationCashInfo = lazy(() => import('./Components/DestinationCashInfo'))

const HotDestinationCurrencyComponent = hot(DestinationCurrencyComponent)
const HotDestinationCurrencyPriceIndexInfoComponent = hot(DestinationCurrencyPriceIndexInfoComponent)
const HotDestinationCashInfo = hot(DestinationCashInfo)
const HotConversionListComponent = hot(ConversionListComponent)

export function initDestinationComponents() {
	let destinationCurrencyComponent = document.getElementById('destinationCurrency')
	let destinationCurrencyPriceIndexInfo = document.getElementById('destinationCurrencyPriceIndexInfo')

	if (destinationCurrencyComponent && destinationCurrencyPriceIndexInfo) {
		let destinationService = new DestinationService()
		const countryWithProductsPromise = destinationService.getCountry(destinationPageModel.CountryCode)
		const baseCountryPromise = destinationService.getCountry(destinationPageModel.BaseCountryCode)
	 	const priceCategoriesPromise = destinationService.getPriceCategories(destinationPageModel.BaseCountryCode)

		Promise.all([countryWithProductsPromise, priceCategoriesPromise,baseCountryPromise])
			.then(([countryWithProducts, priceCategories, baseCountry]) => [countryWithProducts, get(priceCategories, '[0]', { fromSourceExchangeRates: [] }),
			 baseCountry])	
			.then(([countryWithProducts, priceCategory,baseCountry]) => {	
				const baseCurrencyCode = priceCategory.currencyCode
				const baseCountryIndex = baseCountry.cashIndex
				const currencyLinks = destinationPageModel.CurrencyLinks || {}
				let destinationCurrencies = countryWithProducts.products.map(product => ({
					name: product.name,
					flagCode: product.flagCode,
					currencyCode: product.currencyCode,
					exchangeRate: get(priceCategory.fromSourceExchangeRates.find(rate => get(rate, 'targetProduct.id', '') === product.id), 'rate', 0),
					isPrimary: product.isPrimary,
					importExportLimitation: product.importExportLimitation,
					importExportBan: product.importExportBan,
					url: get(currencyLinks[product.id], 'Url', `/404/${product.id}`)
				}))			

				try {
					ReactDOM.render(
						<Suspense fallback={<div></div>}>
						<HotDestinationCurrencyComponent
							destinationCurrencies={destinationCurrencies}
							baseCurrencyCode={baseCurrencyCode}
							exchangeAmount={destinationPageModel.ExampleExchangeAmount}
							countryName={countryWithProducts.name}
						/>
						</Suspense>,
						destinationCurrencyComponent
					)
				} catch (err) {
					renderErrorComponent(destinationCurrencyComponent)
					trackErrorEvent(err, 'destinationPageServiceError', window.location.pathname)
				}

				try {
					let priceEstimates = get(countryWithProducts, 'priceEstimates', {})
					let exchangeRate = get(destinationCurrencies.find(c => c.isPrimary), 'exchangeRate', 0)
					if (!isEmpty(priceEstimates)) {
						ReactDOM.render(
							<Suspense fallback={<div></div>}>
							<HotDestinationCurrencyPriceIndexInfoComponent
								priceEstimates={get(countryWithProducts, 'priceEstimates', {})}
								exchangeRate={exchangeRate}
								baseCurrency={baseCurrencyCode}
								priceEstimationHeading={destinationPageModel.PriceEstimatesHeading}
							/>
							<HotDestinationCashInfo 
								cashIndex = {countryWithProducts.cashIndex}
								baseCashIndex = {baseCountryIndex}
								countryName = {countryWithProducts.name}
								baseCountryName = {baseCountry.name}
								/>
							</Suspense>,
							destinationCurrencyPriceIndexInfo
							
						)
					} else {
						ReactDOM.render(
							<Suspense fallback={<div></div>}>
							<HotConversionListComponent
								heading={resources.Exchange_rate_examples || ''}
								sourceCurrencyCode={baseCurrencyCode}
								targetCurrencyCode={get(destinationCurrencies.find(c => c.isPrimary), 'currencyCode', '')}
								rate={exchangeRate}
								amountList={[1, 50, 100, 500]}
							/>
							</Suspense>,
							destinationCurrencyPriceIndexInfo
						)

					}
				} catch (err) {
					renderErrorComponent(destinationCurrencyPriceIndexInfo)
					trackErrorEvent(err, 'destinationPageServiceError', window.location.pathname)
				}

			}).catch((err) => {
				renderErrorComponent(destinationCurrencyComponent)
				renderErrorComponent(destinationCurrencyPriceIndexInfo)

				trackErrorEvent(err, 'destinationPageServiceError', window.location.pathname)
			})
	}
}