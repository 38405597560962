import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const ExchangeRatesComponent = lazy(() => import('./Components/ExchangeRatesComponent'))

const HotExchangeRatesComponent = hot(ExchangeRatesComponent)

export function initExchangeRatesComponents() {
	let currencyListing = document.getElementById('currency-listing')

	if (currencyListing !== null) {

		ReactDOM.render(
			<Suspense fallback={<div></div>}>
				{!exchangeRatesModel.ToggleConverterBlock ? 
					<HotExchangeRatesComponent baseCountryCode={exchangeRatesModel.BaseCountryCode}
						currencyServiceUrl={exchangeRatesModel.CurrencyServiceUrl}
						currencyConverterPageUrl={exchangeRatesModel.CurrencyConverterPageUrl}
						findStorePageUrl={exchangeRatesModel.FindStorePageUrl}
						exampleExchangeAmount={exchangeRatesModel.ExampleExchangeAmount}
						exampleTargetCurrencyExchangeAmount={exchangeRatesModel.ExampleTargetCurrencyExchangeAmount}
						currencyLinks={exchangeRatesModel.CurrencyLinks}
						toggleConverterBlock={exchangeRatesModel.ToggleConverterBlock}
					/>
				
				: null}
			</Suspense>,
			currencyListing
		)
	}
}