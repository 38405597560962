import { combineReducers } from 'redux'
import {PRIMARY_, SECONDARY_, _PEP_QUESTIONS, _ACCOUNT_QUESTIONS, _PERSONAL_INFORMATION,
	_TAX_QUESTIONS, ALL_, NEXT_STEP} from '../Constants/action-types'

import transitions from './stateMachine'
import mainInformationFor from 'Scripts/CommonComponents/ApplicationForm/Reducers/mainInformation'
import secondaryApplicantQuestion from 'Scripts/CommonComponents/ApplicationForm/Reducers/secondaryApplicantQuestion'
import confirmation from './confirmation'
import kycQuestions from './kycQuestionsReducer'
import exits from 'Scripts/CommonComponents/ApplicationForm/Reducers/exits'
import formReducerFor from 'Scripts/CommonComponents/ApplicationForm/Reducers/formReducerFor'
import personalInformationFor from 'Scripts/CommonComponents/ApplicationForm/Reducers/personalInformation'
import { reducer as notificationMessage } from 'Scripts/CommonComponents/NotificationMessageComponent/reducer'
import { states } from '../Constants/states'
import { trackEvent, trackPageView } from 'Scripts/Shared/analyticsEventTracking'

const combinedReducers = combineReducers({
	transitions,
	primaryApplicant: combineReducers({
		mainInformation: mainInformationFor(PRIMARY_),
		personalInformation: personalInformationFor(PRIMARY_),
		pepQuestions: formReducerFor(PRIMARY_, _PEP_QUESTIONS),
		taxQuestions: formReducerFor(PRIMARY_, _TAX_QUESTIONS)
	}),
	secondaryApplicant: combineReducers({
		secondaryApplicantQuestion: secondaryApplicantQuestion,
		mainInformation: mainInformationFor(SECONDARY_),
		personalInformation: personalInformationFor(SECONDARY_),
		pepQuestions: formReducerFor(SECONDARY_, _PEP_QUESTIONS),
		taxQuestions: formReducerFor(SECONDARY_, _TAX_QUESTIONS),
	}),
	accountQuestions: formReducerFor(ALL_, _ACCOUNT_QUESTIONS),
	kycQuestions,
	confirmation,
	exits,
	notificationMessage
})

const notifyGA = (step, state) => {
	switch(step) {
		case states.start:
			trackEvent('Account', 'initAccount', 'Start', null)
			trackPageView('virtual-accountinitstep', 'Account init')
			break
		case states.pepQuestions:
			trackPageView('virtual-accountpepstep', 'Account PEP questions')
			break
		case states.taxQuestions:
			trackPageView('virtual-accounttaxquestionstep', 'Account Tax questions')
			break
		case states.fellowInformation:
			trackPageView('virtual-accountcoapplicantsstep', 'Account Coapplicant')
			break
		case states.accountQuestions:
			trackPageView('virtual-accountinfostep', 'Account Accountinfo step')
			break
		case states.personalInformation2:
			trackPageView('virtual-accountcoapplicantpersonalinfostep', 'Account Coapplicant personal info')
			break
		case states.pepQuestions2:
			trackPageView('virtual-accountcoapplicantpepstep', 'Account Coapplicant PEP questions')
			break
		case states.taxQuestions2:
			trackPageView('virtual-accountcoapplicanttaxquestionstep', 'Account Coapplicant Tax questions')
			break
		case states.accountQuestions2:
			trackPageView('virtual-accountcoapplicantinfostep', 'Account Coapplicant Accountinfo step')
			break
		case states.confirmation:
			trackPageView('virtual-accountsignstep', 'Account Sign step')
			break
		case states.exits:
		default:
			break
	}
}

export const rootReducer = (state, action) => {
	switch(action.type) {
		//implement action-logic shared between all reducers, i.e. google analytics, reset, ...
		case NEXT_STEP:
			notifyGA(action.payload, state)
			break
		default:
			break
	}
	return combinedReducers(state, action)
}