import { hot } from 'react-hot-loader/root'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

const LocateStoreContainerComponent = lazy(() => import('./Components/LocateStoreContainerComponent'))
const LocateStoreComponent = lazy(() => import('./Components/LocateStoreComponent'))
const LocateStoreListComponent = lazy(() => import('./Components/LocateStoreListComponent'))

const HotLocateStoreContainerComponent = hot(LocateStoreContainerComponent)
const HotLocateStoreComponent = hot(LocateStoreComponent)
const HotLocateStoreListComponent = hot(LocateStoreListComponent)

export function initLocateStoreComponent() {
	const locateStorePageApp = document.getElementById('locate-store-page-app')

	if (locateStorePageApp) {
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
			<HotLocateStoreContainerComponent
				initLong={findStoreLocationModel.LocateStoreBlockModel.InitialFocusLongitude}
				initLat={findStoreLocationModel.LocateStoreBlockModel.InitialFocusLatitude}
				headerText={findStoreLocationModel.LocateStoreBlockModel.Header}
				teaserText={findStoreLocationModel.LocateStoreBlockModel.Body}
				initialZoom={findStoreLocationModel.LocateStoreBlockModel.InitialZoomLevel}
				storeZoom={findStoreLocationModel.LocateStoreBlockModel.StoreZoomLevel}
				googleMapsApiKey={findStoreLocationModel.LocateStoreBlockModel.GoogleMapsApiKey}
				storeServiceUrl={findStoreLocationModel.ForexStoresServiceUrl}
				currentMarket={findStoreLocationModel.CurrentMarket}
			/>
			</Suspense>,
			locateStorePageApp
		)
	}

	const locateStoreApps = document.getElementsByClassName('locateStoreApp')
	
	if (locateStoreApps !== undefined) {
		for (var item of locateStoreApps) {
			ReactDOM.render(
				<Suspense fallback={<div></div>}>
				<HotLocateStoreComponent
					initLong={locateStoreViewModel.InitialFocusLongitude}
					initLat={locateStoreViewModel.InitialFocusLatitude}
					headerText={locateStoreViewModel.Header}
					teaserText={locateStoreViewModel.Body}
					initialZoom={locateStoreViewModel.InitialZoomLevel}
					storeZoom={locateStoreViewModel.StoreZoomLevel}
					currencyServiceUrl={locateStoreViewModel.CurrencyServiceUrl}
					googleMapsApiKey={locateStoreViewModel.GoogleMapsApiKey}
				/>
				</Suspense>,
				item
			)
		}
	}

	const storeListings = document.getElementsByClassName('storeListing')
	if (storeListings !== undefined) {
		for (var item of storeListings) {
			ReactDOM.render(
				<Suspense fallback={<div></div>}>
				<HotLocateStoreListComponent
					storeServiceUrl={findStoreLocationModel.ForexStoresServiceUrl}
					currentMarket={findStoreLocationModel.CurrentMarket}
				/>
				</Suspense>,
				item
			)
		}
	}
}