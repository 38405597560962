import { INIT_PERSONAL_INFORMATION, TOGGLE_PERSONAL_INFORMATION, 
	UPDATE_PERSONAL_INFORMATION, PERSONAL_INFORMATION_DONE, 
	RESET_PERSONAL_INFORMATION, RESET_ALL, DISABLE,
	PERSONAL_INFORMATION_HAS_AGREED_TO_MARKETING } from '../Constants/action-types'
import {status, state, initialFormData} from 'Scripts/CommonComponents/ApplicationForm/Constants/sharedInitialState'

const initialState = Object.assign({
	id: 'personalInformation',
	form: {
		mail: initialFormData,
		phoneNumber: initialFormData,
	},
	hasAgreedToMarketing: undefined, 
	status
  }, state)

const personalInformationFor = (prefix) => { 
	return (state = initialState, action) => {
		switch (action.type) {
			case prefix + INIT_PERSONAL_INFORMATION: 
				return Object.assign({}, state, { 
					choosenStep: action.availableSteps[0],
					parentStep: action.parentStep,
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
				})
			case prefix + TOGGLE_PERSONAL_INFORMATION:
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, 
						{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
				})
			case prefix + UPDATE_PERSONAL_INFORMATION:
				return Object.assign({}, state, {
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false}),
					form: action.form
				})
			case prefix + PERSONAL_INFORMATION_DONE: 
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
				})
			case prefix + DISABLE:
				return Object.assign({}, state, {
					status
				})
			case prefix + PERSONAL_INFORMATION_HAS_AGREED_TO_MARKETING:
				return Object.assign({}, state, {
					hasAgreedToMarketing: action.agreed
				})
			case prefix + RESET_PERSONAL_INFORMATION:
			case prefix + RESET_ALL:
			case RESET_ALL:
				return initialState
	    	default:
				return state
		}
	}
}


export default personalInformationFor