import { SET_EXIT, RESET_EXIT } from '../Constants/action-types'

const initialState = {
	id: 'exits',
	show: false,
	exitCode: undefined,
	retry: undefined
}

const exits = (state = initialState, action) => {		
	switch (action.type) {
		case SET_EXIT: 
			return Object.assign({}, state, { 
				show: true,
				exitCode: action.exitCode,
				retry: action.retry
			})
		case RESET_EXIT: 		
			return Object.assign({}, initialState)
    	default:
			return state
	}
}

export default exits