import { SHOW, HIDE } from './constants'

export const show = (message) => {
    return {
        type: SHOW,
        message
    }
}

export const hide = () => ({
    type: HIDE
})