import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const ActiveOfferComponent = lazy(() => import('./Components/ActiveOfferComponent'))
const HotActiveOfferComponent = hot(ActiveOfferComponent)

export function initActiveOfferComponent() {
    let activeOfferApp = document.getElementById('activeOffer')

    if (activeOfferApp) {
        ReactDOM.render(
            <Suspense fallback={<div></div>}>
                <HotActiveOfferComponent />
            </Suspense>,
            activeOfferApp
        )

    }
}	