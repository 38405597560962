export default class Header {

	constructor() {
		const originalHeader = document.getElementById('header')
		if (originalHeader) {
			const stickyHeader = originalHeader.getAttribute('sticky')
			if (stickyHeader) {
				this.initHeader()
			}
		}
	}
	initHeader() {
		window.onscroll = function () { makeStickyHeader() }
		const searchInput = document.getElementById('searchInput')
		const topSection = document.getElementById('topSection')
		const searchContainer = document.querySelector('.search-container')
		searchInput.classList.add('header__sticky-header__input')
		topSection.classList.add('top-section-wrapper__sticky')
		searchContainer.classList.add('search-container__sticky')
	}
}

let tmpOffset = 0

function makeStickyHeader() {
	const stickyHeaderWrapper = document.querySelector('body')
	const stickyHeader = document.getElementById('header')
	const topSection = document.getElementById('topSection')
	const notificationBlocks = document.querySelector('.notification-blocks')
	const headerWrapper = document.getElementById('header-wrapper')

	const sticky = stickyHeader.offsetTop
	const pageYOffset = window.pageYOffset

	if (pageYOffset <= tmpOffset) {
		topSection.classList.remove('header__sticky-header')
		headerWrapper.classList.remove('header-wrapper__sticky')
		headerWrapper.classList.add('header-wrapper')
		stickyHeaderWrapper.style.paddingTop = 0
		if (notificationBlocks) notificationBlocks.style.display = 'block'
	} else if (pageYOffset > sticky) {
		if (sticky > 0) {
			tmpOffset = sticky
		}
		topSection.classList.add('header__sticky-header')
		headerWrapper.classList.add('header-wrapper__sticky')
		headerWrapper.classList.remove('header-wrapper')
		stickyHeaderWrapper.style.paddingTop = `${stickyHeader.getBoundingClientRect().height + tmpOffset}px`
		if (notificationBlocks) notificationBlocks.style.display = 'none'
	}
}