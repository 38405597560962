import { hot } from 'react-hot-loader/root'
import React, { useState, useEffect, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

const CurrencyConverterComponent = lazy(() => import('./Components/CurrencyConverterComponent'))
const RelatedInfoComponent = lazy(() => import('./Components/RelatedInfoComponent'))

const ConverterIndex = ({currencyInfo}) => {
	const [isSummaryOpen, setIsSummaryOpen] = useState(false)
	const [isMobile, setIsMobile] = useState(window.screen.width < 900)

	useEffect(() => {

		function handleResize(){
			setIsMobile(window.screen.width < 900  )	
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		  
	  }  
  
	}, [])

	return (
		<Suspense fallback={<div></div>}>
			<CurrencyConverterComponent 
					isSummaryOpen={isSummaryOpen}
					setIsSummaryOpen={setIsSummaryOpen}
					baseCountryCode={currencyInfo.BaseCountryCode}
					selectedCurrency={currencyInfo.SelectedCurrency ?
						currencyInfo.SelectedCurrency :
						currencyInfo.BaseTargetCurrencyCode}
					selectedDestination={currencyInfo.SelectedDestination}
					basketPageUrl={currencyInfo.BasketPageUrl}
					findStorePageUrl={currencyInfo.FindStorePageUrl}
					customerCarePageUrl={currencyInfo.CustomerCarePageUrl}
					initiallySelling={currencyInfo.InitiallySelling}
					isOnCurrencyConverterPage={currencyInfo.IsOnCurrencyConverterPage}
					currencyBuybackPageUrl={currencyInfo.CurrencyBuybackPageUrl}
					currencyConverterPageUrl={currencyInfo.CurrencyConverterPageUrl}
					titleBuy={currencyInfo.TitleBuy}
					teaserBuy={currencyInfo.TeaserBuy}
					titleSell={currencyInfo.TitleSell}
					teaserSell={currencyInfo.TeaserSell}
					fromAmount={currencyInfo.FromAmount}
					toAmount={currencyInfo.ToAmount}
					currencyServiceUrl={currencyInfo.CurrencyServiceUrl}
					ctaButtonText={currencyInfo.CtaButtonText}
					ctaButtonLink={currencyInfo.CtaButtonLink}
					saveToBasket={currencyInfo.SaveToBasket}
					heroStyle={currencyInfo.HeroStyle}
					showPriceEstimates={currencyInfo.ShowPriceEstimates}
					showInfoInstantly={currencyInfo.ShowInfoInstantly}
					uniqueSellingPoints={currencyInfo.UniqueSellingPoints}
			/>
		</Suspense>
	)

}

const HotConverterIndex = hot(ConverterIndex)
const HotRelatedInfoComponent = hot(RelatedInfoComponent)

export function initCurrencyConverterComponent() {
	const currencyConverter = document.getElementById('currency-converter')
	const relatedInfo = document.getElementById('related-info')

	if (currencyConverter !== null) {
		let currencyInfo = currencyConverterModel

		ReactDOM.render(
			<HotConverterIndex currencyInfo={currencyInfo} />
			,
			currencyConverter
		)
	}

	if (relatedInfo !== null) {
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
				<HotRelatedInfoComponent
					teaserHeading={relatedInfoModel.TeaserHeading}
					teaserBody={relatedInfoModel.TeaserBody}
				/>
			</Suspense>,
			relatedInfo
		)
	}
}
