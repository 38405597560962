export const status = {
	minimized: true,
	active: false, 
	done: false,
	disabled: true
}

export const state = {
	parentStep: null, 
	choosenStep: null, 
	availableSteps: []
}
