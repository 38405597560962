import {INIT_APPROVE, TOGGLE_APPROVE, APPROVE_DONE, 
	RESET_APPROVE, RESET_ALL} from '../Constants/action-types'
import {status} from '../Constants/sharedInitialState'

const initialState = Object.assign({
	id: 'approve',
	status
  })

const approve = (state = initialState, action) => {
	switch (action.type) {
		case INIT_APPROVE: 
			return Object.assign({}, state, { 
				choosenStep: action.availableSteps[0],
				parentStep: action.parentStep,
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false})  
			})
		case TOGGLE_APPROVE:
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, 
					{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
			})
		case APPROVE_DONE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
			})
		case RESET_APPROVE:
		case RESET_ALL:
			return initialState
    	default:
			return state
  }
}


export default approve