import {getCookie, setCockie} from 'Scripts/Helpers/cookieHelper'
import SessionStorageHelper from 'Scripts/Helpers/sessionStorageHelper'
import { EcomEventTracker } from 'Scripts/Shared/analyticsEventTracking'

export default class loanConfirmation {
	constructor() {
		this.init()

		this.ecomEventTracker = new EcomEventTracker()
	}

	init() {
		this.acceptLoan
	}

	clearSessionOfLoan() {
		let sessionStorageHelper = new SessionStorageHelper()
		sessionStorageHelper.delete(LOAN_STATE_KEY)
	}

	acceptLoan(orderId, loanCalculatedAmmount) {
		let gaTrackEcomCookie = getCookie('gaTrackEcom-'+orderId)

		if(!gaTrackEcomCookie) {
			this.ecomEventTracker.trackEcomTransaction(orderId, 'loan', loanCalculatedAmmount, '', '')
			this.ecomEventTracker.trackEcomItem('loanItem-'+orderId, 'Loan', 'PrivateLoan', 'Loan', loanCalculatedAmmount, '1')
			this.ecomEventTracker.commitEcomTransactions()

			this.clearSessionOfLoan
			setCookie('gaTrackEcom-'+orderId, 'true', 30)
		}
	}
}