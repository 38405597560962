import { HttpService, currencyServiceApi, authServiceApi, paymentServiceApi } from 'Scripts/Shared'
import CustomerApiService from 'Scripts/Shared/services/customerApiService'
import CurrencyApiService from 'Scripts/Shared/services/currencyApiService'
import { fromJsonApi, toJsonApi } from 'Scripts/Shared/jsonApi'

export default class BasketApiService {
	constructor(currencyServiceUrl, authServiceUrl, paymentServiceUrl, customerServiceUrl) {
		this.authServiceUrl = authServiceUrl
		this.currencyServiceUrl = currencyServiceUrl
		this.paymentServiceUrl = paymentServiceUrl
		this.customerServiceUrl = customerServiceUrl
		this.customerService = new CustomerApiService()
		this.currencyService = new CurrencyApiService()
		this.http = new HttpService()
	}

	getCustomerCampaign(accessToken) {
		return this.customerService.getCustomerCampaign(accessToken)
	}

	getCampaignbyId(market, id) {
		return this.currencyService.getCampaign(market, id)
	}

	getCurrencyDetailsFromExchangeRate(exchangeRateId) {
		const { url, includes } = currencyServiceApi.exchangeRates
		const exchangeRatesUrl = this.currencyServiceUrl + url

		let urlPath = `/${exchangeRateId}`
		let options = {}
		options.include = [includes.targetProduct]

		const query = this.http.createQuery(options)

		return this.http.get(exchangeRatesUrl + urlPath + query)
	}

	getPriceOfTargetCurrency(exchangeRateId, amount) {
		const { url, path } = currencyServiceApi.exchangeRates
		const exchangeRatesUrl = this.currencyServiceUrl + url

		let urlPath = `/${exchangeRateId}/${path.priceoftargetcurrency}/${amount}`

		return this.http.get(exchangeRatesUrl + urlPath)
	}

	postOrder(order, accessToken) {
		const ordersUrl = this.currencyServiceUrl + currencyServiceApi.orders.url

		return this.http.postWithAuthToken(ordersUrl, order, accessToken)
	}

	postReservation(order) {
		const reservationUrl = this.currencyServiceUrl + currencyServiceApi.reservation.url

		return this.http.post(reservationUrl, order)
	}

	getSignicatAuthenticationMethods(redirectUrl) {
		const signicatAuthenticationMethodsUrl = this.authServiceUrl + authServiceApi.signicatAuthenticationMethods.url

		let options = {
			lang: currentCulture
		}
		const marketUrl = redirectUrl.includes('.dk') ? '&market=dnk' : redirectUrl.includes('.fi') ? '&market=fin' : ''
		const query = this.http.createQuery(options) + `&redirectUrl=${redirectUrl}` + marketUrl

		return this.http.get(signicatAuthenticationMethodsUrl + query)
	}

	getCheckoutFlows(countryCode) {
		const { url, filters } = currencyServiceApi.checkoutFlows
		let options = {}
		options.filters = { [filters.market]: countryCode }

		const query = this.http.createQuery(options)
		const market = '&market=' + countryCode

		const checkoutFlowsUrl = this.currencyServiceUrl + url

		return this.http.get(checkoutFlowsUrl + query + market)
	}

	getCustomerInfo(accessToken) {
		return this.customerService.getCustomerInfo(accessToken)
	}

	getCountries() {
		const { url } = currencyServiceApi.countries
		const countriesUrl = this.currencyServiceUrl + url + '?lang=' + currentCulture
		return this.http.get(countriesUrl)
	}

	getCountriesForAreaCode = () => this.customerService.getCountries()
		.then(response => fromJsonApi(response))

	getCustomerKyc(accessToken) {
		return this.customerService.getCustomerKyc(accessToken).then((response) => fromJsonApi(response))
	}

	postCustomerKyc(kycForms, accessToken) {
		return this.customerService.postCustomerKyc(kycForms, accessToken)
	}

	postCitizenship(citizenshipForms, accessToken) {
		return this.customerService.postCustomerCitizenship(citizenshipForms, accessToken)
	}

	getDirectPaymentBanks(selectedMarket) {
		const { market } = paymentServiceApi.direct.path
		let paymentUrl = this.paymentServiceUrl + paymentServiceApi.direct.url + `/${market}/${selectedMarket}`
		return this.http.get(paymentUrl).then(response => fromJsonApi(response))
	}

	registerCardPayment(orderId, redirectUrl, accessToken) {
		let url = this.paymentServiceUrl + paymentServiceApi.card.url + `/${paymentServiceApi.card.path.register}`

		let body = {
			language: currentCulture,
			redirectUrl: redirectUrl,
			orderId: orderId
		}

		return this.http.postWithAuthToken(url, toJsonApi(body), accessToken).then((response) => fromJsonApi(response))
	}

	registerMobilePayPayment(orderId, redirectUrl, accessToken, paymentProvider) {
		const url = this.paymentServiceUrl + paymentServiceApi.mobilepay.url + `/${paymentServiceApi.mobilepay.path.register}`
		const market = redirectUrl.includes('.dk') ? 'DNK' : redirectUrl.includes('.fi') ? 'FIN' : ''

		const body = {
			market,
			language: currentCulture,
			redirectUrl,
			orderId,
			paymentProvider,
		}

		return this.http.postWithAuthToken(url, toJsonApi(body), accessToken).then((response) => fromJsonApi(response))
	}

	registerSwishPayment(orderId, redirectUrl, accessToken, paymentProvider, payerSwishNumber) {
		let url = this.paymentServiceUrl + paymentServiceApi.swish.url + `/${paymentServiceApi.swish.path.register}`

		const productionUrl = 'https://pbw-prep.forex.se/orderbekraftelse'
		const redirectUrlFallback = redirectUrl.includes('http://forex.externalweb.se.local/') ? productionUrl : redirectUrl // TODO: Remove this when backend has added support for local redirect urls.
		
		if (payerSwishNumber && paymentProvider === 'Swish') {
			const payerSwishNumberWithoutPlus = payerSwishNumber.replace('+', '')
			const body = {
				Language: currentCulture,
				PaymentProvider: paymentProvider,
				RedirectUrl: redirectUrlFallback,
				OrderId: orderId,
				PayerSwishNumber: payerSwishNumberWithoutPlus
			}	
			return this.http.postWithAuthToken(url, toJsonApi(body), accessToken).then((response) => fromJsonApi(response))
		}

		if (paymentProvider === 'Swish') {
			const body = {
				Language: currentCulture,
				PaymentProvider: paymentProvider,
				RedirectUrl: redirectUrlFallback,
				OrderId: orderId
			}		
			return this.http.postWithAuthToken(url, toJsonApi(body), accessToken).then((response) => fromJsonApi(response))
		}
		
		let body = {
			language: currentCulture,
			redirectUrl: redirectUrlFallback,
			orderId: orderId,
		}		
		return this.http.postWithAuthToken(url, toJsonApi(body), accessToken).then((response) => fromJsonApi(response))
	}

	finalizeSwishPayment(transactionId, accessToken) {
		let url = this.paymentServiceUrl + paymentServiceApi.swish.url + `/${paymentServiceApi.swish.path.finalize}`

		let body = {
			transactionId: transactionId,
		}

		return this.http.postWithAuthToken(url, body, accessToken)
	}

	registerDirectPayment(orderId, redirectUrl, accessToken, isZimpler) {
		let url = this.paymentServiceUrl + paymentServiceApi.direct.url + `/${paymentServiceApi.direct.path.register}`
		let body = {
			Language: currentCulture,
            redirectUrl: redirectUrl,
            OrderId: orderId,
            DirectPaymentMetod: isZimpler ? 'Zimpler' : 'Nets'
		}

		return this.http.postWithAuthToken(url, toJsonApi(body), accessToken).then((response) => fromJsonApi(response))
	}

	getCurrencyMarket(market) {
		const { url, filters } = currencyServiceApi.markets
		let marketUrl = this.currencyServiceUrl + url

		let options = {}
		options.filters = { [filters.countryCode]: market }

		const query = this.http.createQuery(options)

		return this.http.get(marketUrl + query)
	}
}