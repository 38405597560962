import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const PersonalInformationComponent = lazy(() => import('./Components/PersonalInformationComponent'))
const HotPersonalInformationComponent = hot(PersonalInformationComponent)

export function initPersonalInformationComponent() {
    let personalInformationApp = document.getElementById('personalInformation')

    if (personalInformationApp) {
        var viewModel = personalInformationViewModel || {}
        ReactDOM.render(
            <Suspense fallback={<div></div>}>
                <HotPersonalInformationComponent
                    consentSection={viewModel.consentSection}
                    checkboxEmailText={viewModel.checkboxEmailText}
                    checkboxSmsText={viewModel.checkboxSmsText}
                    checkboxProfilingText={viewModel.checkboxProfilingText}
                    queryToken={viewModel.queryToken}
                    loanInsuranceSection={viewModel.loanInsuranceSection}
                    loanProtectionLegalDocuments={viewModel.loanProtectionLegalDocuments}
                />
            </Suspense>,
            personalInformationApp
        )

    }
}	