export const toJsonApi = (model) => {
    if (!model) return {}

    return Array.isArray(model)
        ? { data: model.map(data => ({ attributes: data, id: data.id })) }
        : { data: { attributes: model, id: model.id } }
}

let fromJsonApiObject = (data, included) => {
    let flattedObject = Object.assign({}, data.attributes, { id: data.id, links: data.links }, data.meta)

    const getIncludedRelation = (relationship) => included
        ? included.find(x => x.type == relationship.type && x.id == relationship.id)
        : undefined

    return data.relationships
        ? Object.entries(data.relationships).reduce((flattedObjectWithRelationships, [key, tempRelationship]) => {
            let relationship = fromJsonApi(tempRelationship)

            flattedObjectWithRelationships[key] = Array.isArray(relationship)
                ? relationship.map(x => Object.assign({}, getIncludedRelation(x), x))
                : Object.assign({}, getIncludedRelation(relationship), relationship)

            return flattedObjectWithRelationships
        }, Object.assign({}, flattedObject))
        : flattedObject
}

export const fromJsonApi = (response) => {
    if (!response || !response.data) return {}

    let included = response.included
        ? response.included.map((data) => fromJsonApiObject(data))
        : []

    return Array.isArray(response.data)
        ? response.data.map((data) => fromJsonApiObject(data, included))
        : fromJsonApiObject(response.data, included)
}