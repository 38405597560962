export default class iframeLoader {

	constructor() {
		this.init()
	}

	init() {
		this.iframeLoader()
	}

	iframeLoader() {
		let iframes = document.getElementsByClassName('js-get-iframe')

		if (iframes == null) {
			return
		}
		else{
			for (let iframe of iframes) {

				let dataSrc = iframe.getAttribute('data-src')
				iframe.setAttribute('src', dataSrc)
			}
		}
	}
}