export default class LocalStorageHelper {
	get(key){
		let item = localStorage.getItem(key)
		return JSON.parse(item)
	}

	set(key, value) {
		let jsonObject = JSON.stringify(value)
		localStorage.setItem(key, jsonObject)
	}

	delete(key) {
		localStorage.removeItem(key)
	}
}