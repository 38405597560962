import {
  TRANSACTION_QUESTIONS_DONE,
  INIT_TRANSACTION_QUESTIONS,
  TOGGLE_TRANSACTION_QUESTIONS,
  RESET_TRANSACTION_QUESTIONS,
  RESET_ALL,
  SET_TRANSACTION_QUESTIONS,
  SET_FORM_ID,
} from "../Constants/action-types";
import { status } from "../Constants/sharedInitialState";

const initialState = Object.assign({
  id: "transactionQuestions",
  status,
  formId: "",
  productType: "Transactions",
  questionsAndOptions: [],
  formId: "",
});

const transactionQuestions = (state = initialState, action) => {
  switch (action.type) {
    case INIT_TRANSACTION_QUESTIONS:
      return Object.assign({}, state, {
        choosenStep: action.availableSteps[0],
        parentStep: action.parentStep,
        status: Object.assign({}, state.status, {
          minimized: false,
          active: true,
          disabled: false,
        }),
      });
    case TOGGLE_TRANSACTION_QUESTIONS:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {
          minimized:
            action.payload != undefined
              ? action.payload
              : !state.status.minimized,
        }),
      });
    case TRANSACTION_QUESTIONS_DONE:
      return Object.assign({}, state, {
        status: Object.assign({}, state.status, {
          done: true,
          minimized: true,
          active: false,
        }),
      });
    case SET_FORM_ID:
      return { ...state, formId: action.formId };
    case SET_TRANSACTION_QUESTIONS:
      return {
        ...state,
        questionsAndOptions: action.questions,
        status: { ...state.status, done: false },
      };
    case RESET_TRANSACTION_QUESTIONS:
      return {
        ...state,
        status: { done: false, minimized: true, active: false, disabled: true },
      };
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default transactionQuestions;
