import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import NotificationMessage from 'Scripts/CommonComponents/NotificationMessageComponent/NotificationMessage'

export function initNotificationMessage(store) {
    const notificationMessage = document.getElementById('notificationMessage')

    if (notificationMessage !== null) {
        ReactDOM.render(
            <Provider store={store}>
                <NotificationMessage defaultMessage={notificationMessageViewModel ? notificationMessageViewModel.message : ''} />
            </Provider>,
            notificationMessage)
    }
}