import MonitorService from 'Scripts/Shared/monitorService'
import { JsonApiError, ApimError, ApiError } from 'Scripts/Shared/errors'
import { authServiceApi } from 'Scripts/Shared'
import LocalStorageHelper from 'Scripts/Helpers/localStorageHelper'
import { config } from 'Scripts/Shared/config'

const createRequest = ({ url, method, body, authToken, contractVersion, optionalHeaders }) => {
	let headers = new Headers()

	if (body || method !== 'GET') headers.append('Content-Type', 'application/json')
	if (authToken) headers.append('Authorization', 'Bearer ' + authToken)
	if (apimSubscriptionKey) headers.append('Ocp-Apim-Subscription-Key', apimSubscriptionKey)
	if (contractVersion) headers.append('X-contractVersion', contractVersion)

	if (optionalHeaders) {
		for (let headerName in optionalHeaders) {
			headers.append(headerName, optionalHeaders[headerName])
		}
	}

	const requestObject = {
		method,
		headers: headers,
		body: JSON.stringify(body)
	}

	const request = window
		.fetch(url, requestObject)
		.then(response => {
			if (response.ok) {
				return response
			}
			return throwHttpError(response)
		})
		.then(response => {
			if (response.status >= 200 && response.status < 300 && response.status !== 204 && response.status !== 205) {
				return response.json()
			}
			return null
		})

	return request
}

const createFormPost = (url, body) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: new URLSearchParams(body)
	}
	const request = window
		.fetch(url, requestOptions)
		.then(response => {
			if (response.ok) return response
			return throwHttpError(response)
		})
		.then(response => {
			if (response.status >= 200 && response.status < 300 && response.status !== 204 && response.status !== 205) {
				return response.json()
			}
			return null
		})
	return request
}

const throwHttpError = (response) => {
	try {
		return response.json().then(e => {
			throw e.errors ? new JsonApiError(e) : new ApimError(e)
		})
	} catch (e) {
		//TODO: cannot parse json, redirect to general error page ex:/400
		console.error(e)
	}

	throw new ApiError(response)
}

const formatFilters = (objects) => {
	let keys = Object.keys(objects)
	if (keys.length) {
		let query = keys
			.map(k => `filter[${k}]=${objects[k]}`)
			.join('&')
		return query + '&'
	} else {
		return ''
	}
}

const formatInclude = (listItems) => {
	if (listItems.length) {
		return `include=${listItems}&`
	} else {
		return ''
	}
}

const formatFields = (objects) => {
	let keys = Object.keys(objects)
	if (keys.length) {
		let query = keys
			.map(k => `field[${k}]=${objects[k]}`)
			.join('&')
		return query + '&'
	} else {
		return ''
	}
}

const formatLang = (lang) => {
	if (lang.length) {
		return `lang=${lang}&`
	} else {
		return ''
	}
}

const shouldRefresh = (authToken) => {
	var token = JSON.parse(atob(authToken.split('.')[1]))
	var expDate = new Date(token.exp * 1000)
	var now = new Date()
	var minutes = 5
	var expMin5 = new Date(expDate.getTime() - minutes * 60000)
	if (now.getTime() > expDate.getTime())
		console.debug('Access token has expired.')
	else if (now.getTime() > expMin5.getTime())
		console.debug('Time to refresh access token.')
	else
		console.debug('No need to refresh access token until', expMin5)
	return now.getTime() > expMin5.getTime() && now.getTime() < expDate.getTime()
}

const checkTokenExpiry = (accessToken, refreshToken, storage, url) => {
	const rtk = storage.get('RTK')
	if (accessToken && !rtk) {
		storage.set('RTK', true)
		if (shouldRefresh(accessToken)) {
			createFormPost(url, { grant_type: 'refresh_token', refresh_token: refreshToken }).then((response) => {
				storage.set('accessToken', response.access_token)
				storage.set('refreshToken', response.refresh_token)
			}).finally(() => { storage.set('RTK', false) })
		}
		else storage.set('RTK', false)
	}
}

export class HttpService {
	constructor() {
		this.monitorService = new MonitorService()
		this.baseUrl = config.serviceUrls.AuthServiceUrl
		this.authServiceApi = authServiceApi
		this.storage = new LocalStorageHelper()
	}

	createRequest(parameters) {
		if (parameters !== undefined) {
			const { url } = this.authServiceApi.accessTokens.refresh
			const baseUrl = this.baseUrl
			const expiryUrl = baseUrl + url
			const accessToken = this.storage.get('accessToken')
			const refreshToken = this.storage.get('refreshToken')
			checkTokenExpiry(accessToken, refreshToken, this.storage, expiryUrl)
		}
		return createRequest(parameters)
			.then((response) => response)
			.catch((error) => {
				if (error instanceof JsonApiError) {
					this.monitorService.trackException(new Error(`JSON Api Error: ${error.title} (${parameters.method} ${parameters.url})`), {
						errorStatusCode: error.statusCode,
						errorId: error.id,
						errorCode: error.code,
						url: parameters.url,
						method: parameters.method
					})
				}
				else if (error instanceof ApiError) {
					this.monitorService.trackException(new Error(`Api Error (${parameters.method} ${parameters.url})`), {
						errorStatusCode: error.statusCode,
						errorMessage: error.message,
						url: parameters.url,
						method: parameters.method
					})
				}
				else if (error instanceof ApimError) {
					this.monitorService.trackException(new Error(`Apim Error (${parameters.method} ${parameters.url})`), {
						errorStatusCode: error.statusCode,
						errorActivityId: error.activityId,
						url: parameters.url,
						method: parameters.method
					})
				}
				else {
					this.monitorService.trackException(new Error(`Unknown Http Error (${parameters.method} ${parameters.url})`), {
						errorStatusCode: error.statusCode,
						errorMessage: error.message,
						url: parameters.url,
						method: parameters.method
					})
				}

				throw error
			})
	}

	get(url, options = {}) {
		return this.createRequest({
			method: 'GET',
			url,
			contractVersion: options.contractVersion,
			headers: options.headers
		})
	}

	post(url, body, options = {}) {
		return this.createRequest({
			method: 'POST',
			url,
			body,
			contractVersion: options.contractVersion,
			optionalHeaders: options.headers
		})
	}

	put(url, body, options = {}) {
		return this.createRequest({
			method: 'PUT',
			url,
			body,
			contractVersion: options.contractVersion,
			optionalHeaders: options.headers
		})
	}

	getWithAuthToken(url, authToken, options = {}) {
		return this.createRequest({
			method: 'GET',
			url,
			authToken: authToken,
			contractVersion: options.contractVersion,
			optionalHeaders: options.headers
		})
	}

	postWithAuthToken(url, body, authToken, options = {}) {
		return this.createRequest({
			method: 'POST',
			url,
			body,
			authToken,
			contractVersion: options.contractVersion,
			optionalHeaders: options.headers
		})
	}

	patchWithAuthToken(url, body, authToken, options = {}) {
		return this.createRequest({
			method: 'PATCH',
			url,
			body,
			authToken,
			contractVersion: options.contractVersion,
			optionalHeaders: options.headers
		})
	}

	putWithAuthToken(url, body, authToken, options = {}) {
		return this.createRequest({
			method: 'PUT',
			url,
			body,
			authToken,
			contractVersion: options.contractVersion,
			optionalHeaders: options.headers
		})
	}

	createQuery(options = {}) {
		const filters = options.filters ? formatFilters(options.filters) : ''
		const include = options.include ? formatInclude(options.include) : ''
		const fields = options.fields ? formatFields(options.fields) : ''
		const lang = options.lang ? formatLang(options.lang) : ''

		let query = '?' + include + fields + filters + lang

		return query.slice(0, -1)
	}

	postForm(url, body) {
		return createFormPost(url, body)
			.then((response) => response)
			.catch((error) => {
				console.error('postForm error:', error)
				this.monitorService.trackException(new Error(`Api Error (${requestOptions.method} ${url})`), {
					errorMessage: error.message,
					url: url,
					method: requestOptions.method
				})
				throw error
			})
	}
}