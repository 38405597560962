import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import LanguageSelectorComponent from './Components/LanguageSelectorComponent'
import LoginToggleComponent from './Components/LoginToggleComponent'
import MediaQuery from 'react-responsive'
import BankIdTokenHandler from 'Scripts/CommonComponents/BankIDAuthentication/BankIdTokenHandler'
import { accessToken } from 'Scripts/CommonComponents/BankIDAuthentication/bankIDTokenNames'

const LoginToggle = () => {
	let loginComponent = <LoginToggleComponent
	displayLogin={loginLinksModel.ShowLoginLinks}
	signInLabel={loginLinksModel.SignInLabel}
	accountLoginLinkItems={loginLinksModel.AccountLoginLinkItems}
	/>
	return <MediaQuery minWidth={1024}>
	{(matches) => {
		if (matches) {
			let loginToggleComponentDesktop = document.getElementById('loginToggleDesktop')
			return ReactDOM.createPortal(loginComponent, loginToggleComponentDesktop)
		} else {
			let loginToggleComponentMobile = document.getElementById('loginToggleMobile')
			return ReactDOM.createPortal(loginComponent, loginToggleComponentMobile)
		}
	}}
</MediaQuery>
}
const HotLanguageSelectorComponent = hot(LanguageSelectorComponent)
const HotLoginToggle = hot(LoginToggle)

export function initHeaderComponent() {
	let languageSelectorComponent = document.getElementById('language-selector')
	let loginToggle = document.getElementById('loginToggle')

	if (languageSelectorComponent !== null) {
		ReactDOM.render(
			<HotLanguageSelectorComponent
				multipleLanguage={languageSelectorModel.MultipleLanguages}
				currentLanguage={languageSelectorModel.CurrentLanguageName}
				availableLanguages={languageSelectorModel.AvailableLanguages}
			/>,
			languageSelectorComponent
		)
	}

	if (loginToggle !== null) {
		ReactDOM.render(
			<HotLoginToggle />,
			loginToggle
		)
	}

	//check if logged in
	const bankIdTokenHandler = new BankIdTokenHandler(accessToken)
	let isValidTokenAndCustomer = bankIdTokenHandler.isValidTokenAndCustomer()

	if (isValidTokenAndCustomer) {
			document.body.classList.add('is-logged-in')
	}
	else {
		document.body.classList.remove('is-logged-in')
	}
}