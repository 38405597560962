import { setCookie } from 'Scripts/Helpers/cookieHelper'
import { getCookie } from 'Scripts/Helpers/cookieHelper'

export function initCookieBanner() {
	let siteCookieBanner = document.getElementById('js-site-cookie-banner')
	let clearCookieBtn = document.getElementById('js-clear-cookie')

	if (siteCookieBanner !== null) {
		checkCookie()

		if (clearCookieBtn !== null) {
			clearCookieBtn.addEventListener('click', function () {
				setCookie('AcceptedCookie', 'true', 90)
				siteCookieBanner.classList.remove('is-visible')
			}, false)
		}
	}

	function checkCookie() {
		var cookie = getCookie('AcceptedCookie')
		if (cookie === '') {
			setTimeout(() => {
				siteCookieBanner.classList.toggle('is-visible')
			}, 1000)
		}
	}
}