import { INIT_MAIN_INFORMATION, TOGGLE_MAIN_INFORMATION, MAIN_INFORMATION_DONE, 
	RESET_MAIN_INFORMATION, RESET_ALL, DISABLE } from '../Constants/action-types'
import {status, state} from 'Scripts/CommonComponents/ApplicationForm/Constants/sharedInitialState'

const initialState = Object.assign({
	id: 'mainInformation',
	status
  }, state)

const mainInformationFor = (prefix) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case prefix + INIT_MAIN_INFORMATION: 
				return Object.assign({}, state, { 
					choosenStep: action.availableSteps[0],
					parentStep: action.parentStep,
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
				})
			case prefix + TOGGLE_MAIN_INFORMATION:
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, 
						{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
				})
			case prefix + MAIN_INFORMATION_DONE: 
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
				})
			case prefix + RESET_MAIN_INFORMATION:
				return Object.assign({}, state, {
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})
				})
			case prefix + DISABLE:
				return Object.assign({}, state, {
					status
				})
			case prefix + RESET_ALL:
			case RESET_ALL:
				return initialState
	    	default:
				return state
		}
	}
}

export default mainInformationFor