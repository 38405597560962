export const currencyServiceApi = {
	currencies: {
		url: 'currencies'
	},
	countries: {
		url: 'countries',
		includes: {
			products: 'products'
		},
		filters: {
			countryCode: 'countryCode'
		}
	},
	priceCategories: {
		url: 'priceCategories',
		includes: {
			fromSourceExchangeRates: {
				value: 'fromSourceExchangeRates',
				sourceProduct: {
					value: 'fromSourceExchangeRates.sourceProduct',
					countries: 'fromSourceExchangeRates.sourceProduct.countries'
				},
				targetProduct: {
					value: 'fromSourceExchangeRates.targetProduct',
					countries: 'fromSourceExchangeRates.targetProduct.countries'
				}
			},
			toSourceExchangeRates: 'toSourceExchangeRates',
		},
		filters: {
			countryCode:'countryCode'
		}
	},
	exchangeRates: {
		url: 'exchangeRates',
		path: {
			exchange: 'exchange',
			priceoftargetcurrency: 'priceoftargetcurrency'
		},
		includes: {
			targetProduct:'targetProduct'
		},
	},
	externalPickupPoints: {
		url : 'externalPickupPoints'
	},
	CampaignCodes: {
		url: 'CampaignCodes'
	},
	orders: {
		url: 'orders'
    },
	reservation: {
		url: 'reservations'
	},
	stores: {
		url: 'stores',
		filters: {
			market:'market'
		}
	},
	checkoutFlows: {
		url: 'checkoutFlows',
		filters: {
			market:'market'
		}
	},
	markets: {
		url: 'markets',
		includes: {
			popularProducts: 'popularProducts'
		},
		filters: {
			countryCode: 'countryCode'
		}
	},
	contents: {
		url: 'contents'
	},
	historicalExchangeRates: {
		url: 'historicalexchangerates',
		path: {
			export: 'export'
		}
	},
	cardExchangeRates: {
		url: 'exchangeRates/_getCardExchangeRate'
	},
	reservationDays: {
		url: 'Reservations/DaysOptions'
	}
}

export const authServiceApi = {
	accessTokens: {
		bankid: {
			url: 'accessTokens/assertion'
		},
		refresh: {
			url: 'accessTokens/refresh'
		}
	},
	signicatAuthenticationMethods: {
		url: 'authenticationMethods/signicat'
	}
}

export const bankIdServiceApi = {
	authOrders: {
		url: 'v2/authorders'
	},
	orders: {
		url:'orders'
	}
}

export const onboardingServiceApi = {
	accounts: {
		url: 'accounts',
		validate: {
			url: 'accounts/_validate'
		}
	},
	agreements: {
		url: 'agreements',
		sign: {
			url: 'agreements/_sign'
		},
		loanProtection: {
			url: 'agreements/loanProtection'
        }
	},
	countries : {
		url: 'countries'
	},
	customers : {
		url: 'customers'
	}
}

export const agreementServiceApi = {
	agreements: {
		url: 'agreements',
		path: {
			document: 'document',
			signOrders: 'signorders_v2'
		}
	},

}

export const paymentServiceApi = {
	direct: {
		url: 'directpayment',
		path: {
			market: 'market',
			order: 'order',
			register: '_register'
		},
	},
	card: {
		url: 'cardpayment',
		path: {
			market: 'market',
			order: 'order',
			register: '_register'
		},
	},
	swish: {
		url: 'swishpayment',
		path: {
			market: 'market',
			order: 'order',
			register: '_register',
			finalize: '_finalize'
		},
	},
	mobilepay: {
		url: 'mobilepaypayment',
		path: {
			market: 'market',
			order: 'order',
			register: '_register',
		},
	},
}

export const customerServiceApi = {
	info: {
		url: ''
	},
	kyc: {
		url: 'kyc'
	},
	transactionForms: {
		url: 'kyc/transactionForms'
	},
	validateTransactionAnswers: {
		url: 'kyc/validateTransactionAnswers'
	},
	citizenship: {
		url: 'InternationalProfile/_update/citizenship'
	},
	crs: {
		url: 'crs/_validate'
	},
	consents: {
		url: 'consents'
	},
	countries: {
		url: 'countries'
	},
	campaigns: {
		url: 'campaigns'
	},
	customer: {
		url: 'v2/Customer'
	},
	contactDetails: {
		url: 'v2/Customer/contactDetails'
	},
	create: {
		url: 'v2/Customer/_create'
	},
	customerInformation: {
		url: 'customerInformation'
	}
}

export const loanServiceApi = {
	formValues: {
		url: 'formValues'
	},
	currentOffer: {
		url: 'currentOffer'
	},
	startLoan: {
		url: '_start'
	},
	acceptOffer: {
		url: '_accept'
	},
	cancelOffer: {
		url: '_cancel'
	},
	person: {
		url: 'persons'
	},
	customer: {
		url: 'customers'
	}
}

export const searchServiceApi = {
	autocomplete: {
		url: 'autocompletes'
	}
}

export const accountServiceApi = {
	accounts: {
		url: 'accounts'
	},
}