import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from './actions'

class NotificationMessage extends Component {
    constructor(props) { super(props) }

    render() {
        if (!this.props.show) return null 

        return <div id="js-message-notification" className="message-notification__container">
            <div className="notification-block__wrapper">
                <div className="notification-block__text-wrapper">
                    <span className="icon icon--notification"></span>
                    <p>{this.props.message ? this.props.message : this.props.defaultMessage}</p>
                </div>
                <div className="notification-block__link-wrapper">
                    <button id="js-message-notification-close-button" className="close-button" onClick={() => this.props.actions.hide()}>
                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                            <path
                                d="M14.913 7.005L7 14.918m.087-7.913L15 14.918"
                                fill="none"
                                fillRule="evenodd"
                                stroke="#FFF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    }
}

NotificationMessage.defaultProps = {
    show: false
}

NotificationMessage.propTypes = {
    show: PropTypes.bool,
    defaultMessage: PropTypes.string
}

function mapStateToProps(state) {
    return {
        show: state.notificationMessage.show,
        message: state.notificationMessage.message
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationMessage)