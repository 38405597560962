import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

const Spinner = (props) => {
	return (<CircularProgress style={{ color: '#000000', backgroundColor: 'transparent' }} size={props.size} thickness={props.thickness} />)
}

Spinner.defaultProps = {
	size: 14,
	thickness: 3.6
}

Spinner.propTypes = {
	size:PropTypes.number,
	thickness: PropTypes.number
}

export default Spinner