import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const ChampionshipsComponent = lazy(() => import('./ChampionshipsComponent'))
const HotChampionshipsComponent = hot(ChampionshipsComponent)

export function initChampionshipsComponent() {
    let championshipsApp = document.getElementById('championships')
    if (championshipsApp) {
        const viewModel = championshipsViewModel || {}
        ReactDOM.render(
            <Suspense fallback={<div></div>}>
                <HotChampionshipsComponent 
                    viewModel={viewModel}
                />
            </Suspense>,
            championshipsApp
        )

    }
}	