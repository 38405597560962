import { INIT, TOGGLE, INIT_FORM, DONE, UPDATE, 
	RESET, RESET_ALL, DISABLE } from 'Scripts/CommonComponents/ApplicationForm/Constants/action-types'
import {status, state} from 'Scripts/CommonComponents/ApplicationForm/Constants/sharedInitialState'

const initialState = Object.assign({
	form: undefined,
	status
  }, state)

const formReducerFor = (prefix, type) => {
	return (state = initialState, action) => {
		switch (action.type) {
			case prefix + INIT + type: 
				return Object.assign({}, state, { 
					choosenStep: action.availableSteps[0],
					parentStep: action.parentStep,
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
				})
			case prefix + TOGGLE + type:
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, 
						{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
				})
			case prefix + INIT_FORM + type:
				return Object.assign({}, state, {
					form: action.form
				})
			case prefix + UPDATE + type: 
				return Object.assign({}, state, {
					status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false}),
					form: action.form
				})
			case prefix + DONE + type: 
				return Object.assign({}, state, { 
					status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
				})
			case prefix + DISABLE:
				return Object.assign({}, state, { 
					status
				})
			case prefix + RESET + type:
			case prefix + RESET_ALL:
			case RESET_ALL:
				return initialState
	    	default:
				return state
	    }
	}
}


export default formReducerFor