import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SearchService from '../Services/SearchService'
import SearchListComponent from './SearchListComponent'
import Spinner from 'Scripts/CommonComponents/ProgressComponent/Spinner'

class SearchFormComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			query: '',
			searchSuggestions: [],
			isFocused: false,
			cursor: -1,
			loading: false,
			searching: false
		}

		const limit = 10

		this.searchService = new SearchService(this.props.searchServiceUrl)

		this.search = this.search.bind(this)
		this.onKeyPress = this.onKeyPress.bind(this)
		this.setComponentWrapperRef = this.setComponentWrapperRef.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleClickOutside = this.handleClickOutside.bind(this)
		this.getSearchSuggestions = this.getSearchSuggestions.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick)
		this.setState({ query: escape(this.props.searchValue) })
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick)
	}

	setComponentWrapperRef(node) {
		this.componentWrapperRef = node
	}

	getSearchSuggestions(seachterm) {
		this.searchService.getSearchSuggestions(seachterm).then((searchSuggestions) => {
			this.setState({ searchSuggestions: searchSuggestions })
		})
	}

	search(e) {
		const val = e.target.value
		let query = escape(val)

		this.setState({ query: query, cursor: -1 })

		this.setState({
			query: val, cursor: -1
		}, () => {
			if (val && val.length > 1) {
				if (val.length % 2 === 0) {
					this.setState({ loading: true, searching: true })
					this.getSearchSuggestions(val)
					this.setState({ loading: false })
				}
			} else if (!this.state.query) {
			}
		})
	}

	onKeyPress(e) {
		switch (e.keyCode) {
			case 13: //Enter
				break
			case 38: //arrow up
				break
			case 40: //arrow down
				break
			case 27: //esc
				//case 9:  //tab
				this.handleClickOutside()
				break
		}
	}

	handleClick(e) {
		if (this.componentWrapperRef && !this.componentWrapperRef.contains(e.target)) {
			this.handleClickOutside()
		}
	}

	handleClickOutside() {
		this.setState({ query: '', isFocused: false, cursor: -1 }, () => {
			this.refs.searchInput.blur()
		})
	}

	closeSearch() {
		this.setState({ query: '', isFocused: false, searching: false, loading: false, cursor: -1 }, () => {
			this.refs.searchInput.blur()
		})
	}

	render() {
		const s = this.state
		let query = encodeURIComponent(s.query)
		let forexUrl = window.location.hostname

		return (
			<div translate="no" className={'search site-search ' + this.props.className} ref={this.setComponentWrapperRef}>
				<div className={`search-container ${this.state.isFocused ? ' focused' : ''}`}>
					<form
						action={this.props.searchPage + `?query=${query}`}
						method='get'
						role='search'
						aria-label={resources.Search_on_forex || ''}
						itemProp='potentialAction'
						itemScope=''
						id='siteSearchForm'
						itemType='http://schema.org/SearchAction'>

						<meta itemProp='target' content={forexUrl + this.props.searchPage + `?query=${query}`} />
						<label htmlFor="searchInput" className="sr-only">{resources.search_on_forex + forexUrl || ''}</label>
						<div translate="no" className={`wrapper-search ${this.state.searchSuggestions.length > 0 ? ' shadow' : ''}`}>
							<input id='convert-input-search'
								ref='searchInput'
								name='query'
								id='searchInput'
								itemProp='query-input'
								onChange={this.handleInputChange}
								className='search-container__input'
								type='text'
								placeholder={resources.Search_on_forex + forexUrl || ''}
								value={unescape(this.state.query)}
								onChange={this.search}
								onKeyDown={this.onKeyPress}
								autoComplete='off'
							/>
							{this.state.query.length > 0
								? <span className='search-container__search-icon' onClick={() => this.closeSearch()}>
									<i className='close-small'></i>
								</span>
								: <span className='search-container__search-icon' onClick={() => { this.refs.searchInput.focus() }}>
									<i className='icon-search'></i>
								</span>
							}
						</div>
						{this.state.searching && this.state.query.length > 0 ?
							<div className={`search-suggestions ${this.state.loading
								|| this.state.searchSuggestions.length ? ' display' : ''} ${this.state.loading ? ' loading' : ''}`}>
								{this.state.loading ?
									<Spinner size={20} thickness={6} /> :
									<SearchListComponent results={this.state.searchSuggestions} searchPage={this.props.searchPage} />
								}
							</div>
							: null
						}
					</form>
				</div>
			</div>
		)
	}
}

SearchFormComponent.propTypes = {
	searchPage: PropTypes.string,
	searchValue: PropTypes.string,
	searchServiceUrl: PropTypes.string
}

export default SearchFormComponent