let instance = null

export default class TransitionsSingleton {
	constructor(){
	  if(!instance){
		instance = this
	  }

	  return instance
	}

	setSteps(steps) {
		this.steps = steps
	}

	getAvailableStates(current) {
		return this.steps[current].childStates.concat()
	}

	getMetaInformation(current) {
		return this.steps ? this.steps[current].metaInformation : {} 
	}
  
}

export const getMetaInformation = (current) => {
	let transitions = new TransitionsSingleton()
	return transitions.getMetaInformation(current)
}

export const getAvailableStates = (current) => {
	let transitions = new TransitionsSingleton()
	return transitions.getAvailableStates(current)
}
