import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const OrderConfirmationComponent = lazy(() => import('./Components/OrderConfirmationComponent'))

const HotOrderConfirmationComponent = hot(OrderConfirmationComponent)

export function initOrderConfirmationComponent() {
	let orderConfirmationPageApp = document.getElementById('orderConfirmationPageApp')
	if (orderConfirmationPageApp !== null) {
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
			<HotOrderConfirmationComponent
				headerLabel={orderConfirmationPageViewModel.HeaderLabel}
				teaserTitle={orderConfirmationPageViewModel.TeaserTitle}
				teaserText={orderConfirmationPageViewModel.TeaserText}
				customerServiceTitle={orderConfirmationPageViewModel.CustomerServiceTitle}
				customerServiceText={orderConfirmationPageViewModel.CustomerServiceText}
				customerServiceButtonName={orderConfirmationPageViewModel.CustomerServiceButtonName} 
				customerServiceButtonUrl={orderConfirmationPageViewModel.CustomerServiceButtonUrl}
				orderInfo={orderConfirmationPageViewModel.OrderInfo}
				orderId={orderConfirmationPageViewModel.OrderId}
				deliveryStoreInformationTitle={orderConfirmationPageViewModel.DeliveryStoreInformationTitle} 
				deliveryStoreInformationText={orderConfirmationPageViewModel.DeliveryStoreInformationText}
				deliveryREKInformationTitle={orderConfirmationPageViewModel.DeliveryREKInformationTitle}
				deliveryREKInformationText={orderConfirmationPageViewModel.DeliveryREKInformationText}
				currencyServiceUrl={orderConfirmationPageViewModel.CurrencyServiceUrl}
				market={orderConfirmationPageViewModel.Market}
			/>
			</Suspense>,
			orderConfirmationPageApp
		)
	}
}