import { combineReducers } from 'redux'

import { CART_DONE, DELIVERY_DONE, PAYMENT_DONE, IDENTIFICATION_DONE, APPROVE_DONE } from '../Constants/action-types'

import cart from './cartReducer'
import identification from './identificationReducer'
import transitions from './stateMachine'
import delivery from './deliveryReducer'
import payment from './paymentReducer'
import approve from './approveReducer'
import { states } from '../Constants/states'
import exits from 'Scripts/CommonComponents/ApplicationForm/Reducers/exits'
import { trackEvent, trackPageView } from 'Scripts/Shared/analyticsEventTracking'
import transactionQuestions from './transactionQuestionsReducer'

const combinedReducers = combineReducers({cart, identification, transitions, delivery, payment, approve, exits, transactionQuestions})

export const rootReducer = (state, action) => {
	let total = 0
	switch(action.type) {
		case CART_DONE:
			trackPageView('delivery', 'Delivery method')
			trackEvent('Order', 'initDelivery', 'delivery', null)
			break
		case DELIVERY_DONE:
			total = parseInt(document.getElementById('summary__total-price').getAttribute('js-total'))
			trackPageView('payment', 'Payment method')
			trackEvent('Order', 'initPayment', 'payment', total)
			break
		case PAYMENT_DONE:
			total = parseInt(document.getElementById('summary__total-price').getAttribute('js-total'))
			trackPageView('information', 'My information')
			trackEvent('Order', 'initInformation', 'information', total)
			break
		case IDENTIFICATION_DONE:
			break
		case APPROVE_DONE:
			let event = action.choosenStep === states.approveReservation ? 'reserve' : 'goToPayment'
			total = parseInt(document.getElementById('summary__total-price').getAttribute('js-total'))
			trackEvent('Order', event, 'confirmation', total)
			break
		default:
			break
	}
	return combinedReducers(state, action)
}