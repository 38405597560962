import { INIT_LOAN_CALCULATOR, TOGGLE_LOAN_CALCULATOR, LOAN_CALCULATOR_DONE, 
	UPDATE_LOAN_CALCULATOR, RESET_LOAN_CALCULATOR, RESET_ALL } from '../Constants/action-types'
import {status, state} from 'Scripts/CommonComponents/ApplicationForm/Constants/sharedInitialState'

const initialState = Object.assign({
	id: 'loanCalculator',
	amount: null, 
	years: null, 
	result: {first: null, last: null, average: null},
	status
  }, state)

const loanCalculator = (state = initialState, action) => {		
	switch (action.type) {
		case INIT_LOAN_CALCULATOR: 
			return Object.assign({}, state, { 
				choosenStep: action.availableSteps[0],
				parentStep: action.parentStep,
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled: false, done: false})  
			})
		case TOGGLE_LOAN_CALCULATOR:
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, 
					{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
			})
		case UPDATE_LOAN_CALCULATOR:
			return Object.assign({}, state, {
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false}),
				amount: action.amount,
				years: action.years,
				result: Object.assign({}, action.result)
			})
		case LOAN_CALCULATOR_DONE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
			})
		case RESET_LOAN_CALCULATOR:
		case RESET_ALL:
			return initialState
    	default:
			return state
	}
}

export default loanCalculator