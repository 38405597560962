import React from 'react'
import ReactDOM from 'react-dom'

export function initConverterWidgetComponent() {
	const ConverterWidget = document.getElementById('converter-widget')

	if (ConverterWidget !== null) {
		let cmsSettings = ConverterWidgetModel

		const initialCurrency = cmsSettings.InitialCurrency?.length > 0 ? `&initialCurrency=${cmsSettings.InitialCurrency}` : ''
		const initialCountry = cmsSettings.InitialCountry?.length > 0 ? `&initialCountry=${cmsSettings.InitialCountry}` : ''

		if (cmsSettings.VerticalMode === true) {
			ReactDOM.render(
				<div style={{ width: `${cmsSettings.ContainerWidth}px`, margin: '0 auto'}}>
					<iframe 
						src={`https://converterwidget.forex.se/?vertical=true${initialCurrency}${initialCountry}`}
						width="100%"
						height="830"
						style={{border: 'none', overflow: 'hidden'}}
						>	
					</iframe>
				</div>
				,
				ConverterWidget
			)
		}
		else {
			ReactDOM.render(
				<div style={{ width: `${cmsSettings.ContainerWidth}px`, margin: '0 auto'}}>
					<iframe
						src={`https://converterwidget.forex.se/?vertical=false${initialCurrency}${initialCountry}`}
						width="100%"
						height={cmsSettings.ContainerWidth > 839 ? '570' :
							 	cmsSettings.ContainerWidth > 662 ?  '756' :
								cmsSettings.ContainerWidth > 445 ?  '772' :
								cmsSettings.ContainerWidth > 365 ?  '788' :
								 '830'}
						style={{border: 'none', overflow: 'hidden'}}
						>	
					</iframe>
				</div>
				,
				ConverterWidget
			)
		}
	}
}
