import '../Content/main.scss'
import { HttpService } from './Shared/http'
import { initCurrencyConverterComponent } from '../Features/CurrencyConverter/Scripts'
import { initBankCurrencyConverterComponent } from '../Features/BankCurrencyConverterPage/Scripts'
import { initConverterWidgetComponent } from '../Features/ConverterWidget/ConverterWidgetBlock'
import { initBasketComponent } from '../Features/BasketPage/Scripts'
import { initReservationConfirmationComponent } from '../Features/ReservationConfirmationPage/Scripts'
import { initOrderConfirmationComponent } from '../Features/OrderConfirmationPage/Scripts'
import { initLocateStoreComponent } from '../Features/LocateStore/Scripts'
import { initPopularCurrenciesComponent } from '../Features/PopularCurrencies/Scripts'
import { initHeaderComponent } from '../Features/Header/Scripts'
import { initMobileNavigation } from './Shared/mobileNavigation'
import SearchPagination from './Shared/paginateSearch'
import SearchBar from './Shared/searchBar'
import Header from './Shared/header'
import { initSearchBarComponent } from '../Features/Search/Scripts'
import { initFooterComponent } from '../Features/Footer/Scripts'
import { initDestinationComponents } from '../Features/Destination/DestinationPage/Scripts'
import GAEventTracking, { GAEventInitializer } from './Shared/analyticsEventTracking'
import iframeLoader from './Shared/iframe'
import { initShowHideContent } from './Shared/showHideContent'
import { initTableWrapp } from './Shared/tables'
import { initPrivateAccountApp } from '../Features/PrivateAccount/PrivateAccountApplicationPage/Scripts'
import { initCookieBanner } from './Shared/cookieBanner'
import { initNotificationBlock } from './Shared/notificationBlock'
import { initExchangeRatesComponents } from '../Features/ExchangeRates/ExchangeRatesPage/Scripts'
import { initContainerAccordionBlocks } from './Shared/containerAccordionBlocks'
import { initLoanApp } from '../Features/Loan/LoanPage/Scripts'
import { initLoanCalculatorApp } from '../Features/Loan/LoanCalculatorBlock/Scripts'
import { initLoanOfferApp } from '../Features/Loan/LoanOfferPage/Scripts'
import loanConfirmation from './Shared/loanConfirmation'
import { initPersonalInformationComponent } from '../Features/PersonalInformationPage/Scripts'
import { initActiveOfferComponent } from '../Features/ActiveOfferPage/Scripts'
import { initCollectConsentContainer } from '../Features/CollectConsentPage/Scripts/Components'
import { initSiteAdBanner } from './Shared/siteAdBanner'
import { initSiteAdComponents } from '../Features/SiteAd/Shared/Scripts'
import { initChampionshipsComponent } from '../Features/ChampionshipsPage/Scripts/Components'
import { initHeroConverrterBlockComponent } from '../Features/HeroConverterBlock/Scripts'
import { initExchangeConverterBlockComponent } from '../Features/ExchangeConverterBlock/Scripts'
import { initCustomerFileUploadContainer } from '../Features/CustomerFileUpload/CustomerFileUploadPage/Scripts'


if (module.hot) {
	module.hot.accept()
}

document.addEventListener('DOMContentLoaded', () => {
	let http = new HttpService()
	window.currentCulture = document.querySelector('meta[name=culture]').content
	window.resources = {}
	window.abortController = new AbortController()

	//get resources for localizaton
	http.get(`/labels.${currentCulture}`).then(res => {
		window.resources = res
	})
		.then(() => {
			initReactComponents(() => {
				window.gaEventTracking = new GAEventTracking()
				let gaEventInitializer = new GAEventInitializer(window.gaEventTracking)
				gaEventInitializer.init()
				window.loanConfirmation = new loanConfirmation()
				window.iframeLoader = new iframeLoader()
				window.searchBar = new SearchBar()
				window.header = new Header()
				window.searchPagination = new SearchPagination()
			})
		}).catch(() => {
			initReactComponents(() => {
				window.gaEventTracking = new GAEventTracking()
				let gaEventInitializer = new GAEventInitializer(window.gaEventTracking)
				gaEventInitializer.init()
				window.loanConfirmation = new loanConfirmation()
				window.iframeLoader = new iframeLoader()
				window.searchBar = new SearchBar()
				window.header = new Header()
				window.searchPagination = new SearchPagination()
			})
		})

	initMobileNavigation()
	initShowHideContent()
	initCookieBanner()
	initNotificationBlock()
	initContainerAccordionBlocks()
	initTableWrapp()
	initSiteAdBanner()

	var currentPageInMenu = document.getElementById('js-active-menu-item')

	if (currentPageInMenu !== null) {
		if (currentPageInMenu.classList.contains('has-children')) {
			document.querySelector('.main').classList.add('has-sub-menu')
			document.getElementById('js-sub-menu-container').classList.add('has-sub-items')
		}
	}
})

function initReactComponents(callback) {
	initConverterWidgetComponent()
	initBankCurrencyConverterComponent()
	initCurrencyConverterComponent()
	initBasketComponent()
	initReservationConfirmationComponent()
	initOrderConfirmationComponent()
	initLocateStoreComponent()
	initPopularCurrenciesComponent()
	initHeaderComponent()
	initSearchBarComponent()
	initFooterComponent()
	initPrivateAccountApp()
	initDestinationComponents()
	initExchangeRatesComponents()
	initLoanApp()
	initLoanCalculatorApp()
	initLoanOfferApp()
	initPersonalInformationComponent()
	initActiveOfferComponent()
	initCollectConsentContainer()
	initChampionshipsComponent()
	initSiteAdComponents()
	initHeroConverrterBlockComponent()
	initExchangeConverterBlockComponent()
	callback()
	initCustomerFileUploadContainer()

	let event = document.createEvent('Event')
	event.initEvent('javascriptHasInit', true, true)
	document.dispatchEvent(event)
}