import { hot } from 'react-hot-loader/root'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

const ReservationConfirmationComponent = lazy(() => import('./Components/ReservationConfirmationComponent'))

const HotReservationConfirmationComponent = hot(ReservationConfirmationComponent)

export function initReservationConfirmationComponent() {
	let reservationConfirmationPageApp = document.getElementById('reservationConfirmationPageApp')
	if (reservationConfirmationPageApp !== null) {
		ReactDOM.render(
			<Suspense fallback={<div></div>}>
				<HotReservationConfirmationComponent
					headerLabel={reservationConfirmationPageViewModel.HeaderLabel}
					teaserTitle={reservationConfirmationPageViewModel.TeaserTitle}
					teaserText={reservationConfirmationPageViewModel.TeaserText}
					customerServiceTitle={reservationConfirmationPageViewModel.CustomerServiceTitle}
					customerServiceText={reservationConfirmationPageViewModel.CustomerServiceText}
					customerServiceButtonName={reservationConfirmationPageViewModel.CustomerServiceButtonName} 
					customerServiceButtonUrl={reservationConfirmationPageViewModel.CustomerServiceButtonUrl}
					currencyServiceUrl={reservationConfirmationPageViewModel.CurrencyServiceUrl}
					market={reservationConfirmationPageViewModel.Market}
				/>
			</Suspense>,
			reservationConfirmationPageApp
		)
	}
}