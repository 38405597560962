export const NEXT_STEP = 'NEXT_STEP'
export const RESET_TO_STEP = 'RESET_TO_STEP'
export const INIT_STEPS = 'INIT_STEPS'
export const RESET_ALL = 'RESET_ALL'

export const INIT_CART = 'INIT_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_CART = 'UPDATE_CART'
export const ADD_TO_CAMPAIGN = 'ADD_TO_CAMPAIGN'
export const REMOVE_INCOMPATIBLE_CAMPAGINS = 'REMOVE_INCOMPATIBLE_CAMPAGINS'
export const TOGGLE_CART = 'TOGGLE_CART'
export const CART_DONE = 'CART_DONE'
export const UPDATE_PRICE = 'UPDATE_PRICE'

export const INIT_IDENTIFICATION = 'INIT_IDENTIFICATION'
export const TOGGLE_IDENTIFICATION = 'TOGGLE_IDENTIFICATION'
export const UPDATE_IDENTIFICATION = 'UPDATE_IDENTIFICATION'
export const IDENTIFICATION_DONE = 'IDENTIFICATION_DONE'
export const UPDATE_IDENTIFICATION_IS_IDENTIFIED = 'UPDATE_IDENTIFICATION_IS_IDENTIFIED'
export const RESET_IDENTIFICATION = 'RESET_IDENTIFICATION'
export const IDENTIFICATION_SAVE_SELECTED_BANK = 'IDENTIFICATION_SAVE_SELECTED_BANK'
export const IDENTIFICATION_HAS_AGREED_TO_MARKETING = 'IDENTIFICATION_HAS_AGREED_TO_MARKETING'
export const BACK_TO_IDENTIFICATION = 'BACK_TO_IDENTIFICATION'
export const SAVE_PEP_ANSWER = 'SAVE_PEP_ANSWER'

export const INIT_DELIVERY = 'INIT_DELIVERY'
export const TOGGLE_DELIVERY= 'TOGGLE_DELIVERY'
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY'
export const UPDATE_DELIVERY_STORE = 'UPDATE_DELIVERY_STORE'
export const DELIVERY_DONE = 'DELIVERY_DONE'
export const RESET_DELIVERY = 'RESET_DELIVERY'

export const INIT_PAYMENT = 'INIT_PAYMENT'
export const TOGGLE_PAYMENT= 'TOGGLE_PAYMENT'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const PAYMENT_DONE = 'PAYMENT_DONE'
export const RESET_PAYMENT = 'RESET_PAYMENT'

export const INIT_APPROVE = 'INIT_APPROVE' 
export const TOGGLE_APPROVE = 'TOGGLE_APPROVE'
export const APPROVE_DONE = 'APPROVE_DONE' 
export const RESET_APPROVE = 'RESET_APPROVE'

export const INIT_TRANSACTION_QUESTIONS = 'INIT_TRANSACTION_QUESTIONS'
export const TOGGLE_TRANSACTION_QUESTIONS = 'TOGGLE_TRANSACTION_QUESTIONS'
export const TRANSACTION_QUESTIONS_DONE = 'TRANSACTION_QUESTIONS_DONE'
export const RESET_TRANSACTION_QUESTIONS = 'RESET_TRANSACTION_QUESTIONS'
export const SET_TRANSACTION_QUESTIONS = 'SET_TRANSACTION_QUESTIONS'
export const SET_FORM_ID = 'SET_FORM_ID'