import { setCookie, getCookie } from 'Scripts/Helpers/cookieHelper'

export function initSiteAdBanner() {
	let siteAdBanners = document.querySelectorAll('.js-site-ad-banner')

	if (siteAdBanners !== null) {
		for (let siteAdBanner of siteAdBanners) {

			let hideSiteAdBanner = siteAdBanner.getElementsByClassName('js-hide-site-ad-banner')[0]
			let pageTypeWrapper = document.getElementsByClassName('page-type-wrapper')[0]
			let pageIdentifier = pageTypeWrapper 
				? pageTypeWrapper.getAttribute('data-identifier') 
				: ''
			let cookieIdentifier = siteAdBanner.getAttribute('data-identifier')

			checkCookie(pageIdentifier, cookieIdentifier, siteAdBanner)

			if (hideSiteAdBanner !== null) {
				hideSiteAdBanner.addEventListener('click', function () {
					setCookie('DontShowSiteAd-' + pageIdentifier + '-' + cookieIdentifier, 'true', 7)
					siteAdBanner.classList.remove('is-visible')
				}, false)
			}
		}
	}

	function checkCookie(pageIdentifier, cookieIdentifier, siteAdBanner) {
		var cookie = getCookie('DontShowSiteAd-' + pageIdentifier + '-' + cookieIdentifier)
		if (cookie === '') {
			setTimeout(() => {
				siteAdBanner.classList.toggle('is-visible')
			}, 1000)
		}
	}
}