import { INIT_DELIVERY, TOGGLE_DELIVERY, UPDATE_DELIVERY, UPDATE_DELIVERY_STORE, 
	DELIVERY_DONE, RESET_DELIVERY, RESET_ALL } from '../Constants/action-types'
import { status, state } from '../Constants/sharedInitialState'
import { trackEvent } from 'Scripts/Shared/analyticsEventTracking'

const initialState = Object.assign({
	id: 'delivery',
	store: null,
	pickUpDate: undefined,
	status
  }, state)

const delivery = (state = initialState, action) => {
	switch (action.type) {
		case INIT_DELIVERY: 
			return Object.assign({}, state, { 
				availableSteps: Object.assign([], action.availableSteps),
				parentStep: action.parentStep,
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
			})
		case UPDATE_DELIVERY: 
			trackEvent('Order', `deliveryChange (${action.choosenStep})`, 'delivery', null)
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done:false}),
				choosenStep: action.choosenStep
			})
		case UPDATE_DELIVERY_STORE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done:false}),
				store: action.store,
				pickUpDate: action.pickUpDate  
			})
		case TOGGLE_DELIVERY:
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, 
					{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
			})
		case DELIVERY_DONE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done: true, minimized: true, active: false}),
				choosenStep: action.choosenStep
			})
		case RESET_DELIVERY:
		case RESET_ALL:
			return initialState
    	default:
			return state
  }
}


export default delivery