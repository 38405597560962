import { hot } from 'react-hot-loader/root'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'

const InfoModalComponent = lazy(() => import('./Components/InfoModalComponent'))
const HotInfoModalComponent = hot(InfoModalComponent)

export function initSiteAdComponents() {

	const infoModalReactWidgets = document.getElementsByClassName('infoModal')

	if (infoModalReactWidgets !== undefined) {
		for (var infoModalApp of infoModalReactWidgets) {
			let model = infoModalApp.getAttribute('data-view-model')

			if (!model) {
				return
			}
			model = JSON.parse(model)

			ReactDOM.render(
				<Suspense fallback={<div></div>}>
					<HotInfoModalComponent
						readMoreLabel={model.InfoModalLinkText}
						informationBoxHtmlString={model.InfoModalText}
					/>
				</Suspense>,
			infoModalApp
			)
		}
	}
}