export class JsonApiError {
	constructor(error) {
		//only reads first error from list
		let errorObject = error.errors ? error.errors[0] : error
		for (var k in errorObject) this[k] = errorObject[k]
	}
}

export class ApimError {
	constructor(error) {
		for (var k in error) this[k] = error[k]
	}
}

export class ApiError {
	constructor(response) {
		this.statusCode = response.status
		this.message = response.message
	}
}