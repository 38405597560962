import { INIT_IDENTIFICATION, TOGGLE_IDENTIFICATION, 
	UPDATE_IDENTIFICATION, IDENTIFICATION_SAVE_SELECTED_BANK, 
	BACK_TO_IDENTIFICATION, UPDATE_IDENTIFICATION_IS_IDENTIFIED,
	IDENTIFICATION_DONE, RESET_IDENTIFICATION, RESET_ALL, SAVE_PEP_ANSWER, 
	IDENTIFICATION_HAS_AGREED_TO_MARKETING } from '../Constants/action-types'
import {status, state} from '../Constants/sharedInitialState'

const initialFormData =  {value:'', isValid: false, isTouched: false}

const initialState = Object.assign({
	id: 'identification',
	form: {
		fullName: initialFormData,
		mail: initialFormData,
		phoneNumber: initialFormData,
	},
	selectedBank: undefined,
	identified: false,
	pep: null,
	kycHash: null,
	status,
	hasAgreedToMarketing: false
  }, state)

const identification = (state = initialState, action) => {
	switch (action.type) {
		case INIT_IDENTIFICATION: 
			return Object.assign({}, state, { 
				choosenStep: action.availableSteps[0],
				parentStep: action.parentStep,
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
			})
		case UPDATE_IDENTIFICATION: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done:false}),
				form: Object.assign({}, state.form, action.form) 
			})
		case UPDATE_IDENTIFICATION_IS_IDENTIFIED: 
			return Object.assign({}, state, { 
				identified:  action.identified != undefined ? action.identified : state.identified
			})
		case SAVE_PEP_ANSWER: 
			return Object.assign({}, state, { 
				pep: action.pep,
				kycHash: action.kycHash
			})
		case BACK_TO_IDENTIFICATION: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false}),
				identified: false,
			})
		case TOGGLE_IDENTIFICATION:
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, 
					{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
			})
		case IDENTIFICATION_DONE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done: true, minimized: true, active: false})
			})
		case IDENTIFICATION_SAVE_SELECTED_BANK:
			return Object.assign({}, state, {
				selectedBank: action.selectedBank
			})
		case IDENTIFICATION_HAS_AGREED_TO_MARKETING:
			return Object.assign({}, state, {
				hasAgreedToMarketing: action.agreed
			})
		case RESET_IDENTIFICATION:
		case RESET_ALL:
			return  Object.assign({}, initialState, { identified: state.identified, hasAgreedToMarketing: state.hasAgreedToMarketing })
    	default:
			return state
  }
}


export default identification