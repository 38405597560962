import { config } from 'Scripts/Shared/config'

export const trackEvent = (category, action, label, value) => {
	if (window.gaEventTracking)
		window.gaEventTracking.gaEvent(category, action, label, value)
}

export const trackErrorEvent = (err, component, label) => {
	if (window.gaEventTracking)
		window.gaEventTracking.gaErrorEvent(err, component, label)
}

export const trackPageView = (page, title) => {
	if (window.gaEventTracking)
		window.gaEventTracking.gaPageView(page, title)
}

export class EventTracker {
	constructor() {
		this.eventTracking = new GAEventTracking()
	}

	trackEvent = (category, action, label, value) => this.eventTracking.gaEvent(category, action, label, value)
	trackErrorEvent = (err, component, label) => this.eventTracking.gaErrorEvent(err, component, label)
	trackPageView = (page, title) => this.eventTracking.gaPageView(page, title)
}

export class EcomEventTracker {
	constructor() {
		this.eventTracking = new GAEventTracking()
		this.eventTracking.initGaEcom()
	}

	trackEcomTransaction = (id, affiliation, revenue, shipping, tax) =>
		this.eventTracking.gaTrackEcomTransaction(id, affiliation, revenue, shipping, tax)

	trackEcomItem = (id, currencyCode, type, category, price, quantity) =>
		this.eventTracking.gaTrackEcomItem(id, currencyCode, type, category, price, quantity)

	commitEcomTransactions = () => this.eventTracking.gaSendEcom()
}

export class GAEventInitializer {
	constructor(eventTracking) {
		this.eventTracking = eventTracking
	}

	gaEvent = (category, action, label, value) => this.eventTracking.gaEvent(category, action, label, value)

	init() {
		this.gaTrackRelatedLinksBlockClick()
		this.gaTrackRelatedDocumentLinksBlockClick()
		this.gaTrackHeroAreaClick()
		this.gaTrackTeaserBlockClick()
		this.gaTrackCtaBlockClick()
		this.gaTrackProductTeaserBlockClick()
		this.gaTrackBannerAreaClick()
	}

	trackHtmlElementsClick = (htmlElements, eventFunc) => {
		if (htmlElements) {
			for (let htmlElement of htmlElements) {
				htmlElement.addEventListener('click', () => eventFunc(htmlElement), false)
			}
		}
	}

	gaTrackRelatedLinksBlockClick = () =>
		this.trackHtmlElementsClick(document.getElementsByClassName('js-related-link-click'),
			(htmlElement) => this.gaEvent(
				`Link-click --${this.getIdentifier(htmlElement, '.js-related-links-block')}`,
				window.location.pathname,
				htmlElement.textContent,
				null))

	gaTrackRelatedDocumentLinksBlockClick = () =>
		this.trackHtmlElementsClick(document.getElementsByClassName('js-related-document-link-click'),
			(htmlElement) => this.gaEvent(
				`Link-click-document --${this.getIdentifier(htmlElement, '.js-related-documents-block')}`,
				window.location.pathname,
				htmlElement.textContent,
				null))

	gaTrackHeroAreaClick = () => this.trackHtmlElementsClick(document.getElementsByClassName('js-hero-area-cta'),
		(htmlElement) => this.gaEvent('Page Element', this.getIdentifier(htmlElement, '.js-hero-area'), 'CTA Click', null))

	gaTrackBannerAreaClick = () => this.trackHtmlElementsClick(document.getElementsByClassName('js-banner-cta'),
		(htmlElement) => this.gaEvent('Page Element', this.getIdentifier(htmlElement, '.js-banner-area'), 'CTA Click', null))

	gaTrackTeaserBlockClick = () => this.trackHtmlElementsClick(document.getElementsByClassName('js-teaser-block-link'),
		(htmlElement) => this.gaEvent('Page Element', this.getIdentifier(htmlElement, '.js-teaser-block'), 'CTA Click', null))

	gaTrackProductTeaserBlockClick = () => this.trackHtmlElementsClick(document.getElementsByClassName('js-product-teaser-link'),
		(htmlElement) => this.gaEvent('Page Element', this.getIdentifier(htmlElement, '.js-teaser-block'), 'CTA Click', null))

	gaTrackCtaBlockClick = () => this.trackHtmlElementsClick(document.getElementsByClassName('js-track-cta'),
		(htmlElement) => this.gaEvent('Page Element', this.getIdentifier(htmlElement, '.js-cta-block'), 'CTA Click', null))

	getIdentifier = (htmlElement, identifierClass) => htmlElement.closest(identifierClass).getAttribute('data-identifier')
}

export default class GAEventTracking {
	constructor() {
		this.gaDebug = config.featureToggles.DebugGoogleAnalytics
	}

	initGaEcom = () => ga('require', 'ecommerce')
	gaSendEcom = () => ga('ecommerce:send')

	gaTrackEcomTransaction(id, affiliation, revenue, shipping, tax) {
		ga('ecommerce:addTransaction', {
			'id': id,
			'affiliation': affiliation,
			'revenue': revenue,
			'shipping': shipping,
			'tax': tax
		})

		if (this.gaDebug) {
			console.log(['ecommerce:addTransaction', id, affiliation, revenue, shipping, tax])
		}
	}

	gaTrackEcomItem(id, currencyCode, type, category, price, quantity) {
		ga('ecommerce:addItem', {
			'id': id,
			'name': currencyCode,
			'sku': type,
			'category': category,
			'price': price,
			'quantity': quantity
		})

		if (this.gaDebug) {
			console.log(['ecommerce:addItem', id, currencyCode, type, category, price, quantity])
		}
	}	

	gaEvent(category, action, label, value) {
		if (value != null) {
			value = parseInt(value)
		}

		label = label.replace('↵', '').replace('↵', '').trim()

		if (typeof ga !== 'undefined') {
			ga('send', 'event', category, action, label, value)
		}
		if (typeof _gaq !== 'undefined') {
			_gaq.push(['_trackEvent', category, action, label, value])
		}
		if (this.gaDebug) {
			console.log(['send', 'event', category, action, label, value])
		}
	}

	gaPageView(page, title) {
		let path = document.location.pathname + '/' + page

		if (typeof ga !== 'undefined') {
			ga('send', { 'hitType': 'pageview', 'page': path, 'title': title })
		}
		if (typeof _gaq !== 'undefined') {
			_gaq.push(['_trackPageView', path, title])
		}
		if (this.gaDebug) {
			console.log(['send', 'pageview', path, title])
		}
	}

	gaErrorEvent(err, component, label) {
		if (err.name === 'AbortError') return 

		this.gaEvent('Error', `${err} -${component}`, label.replace('↵', '').replace('↵', '').trim(), null)
	}
}