import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const theme = createTheme({
	overrides: {
		MuiMenuItem: {
			root: {
				color: '#18161F',
				height: '9px',
				fontSize: '13px',
				fontFamily: [
					'Muli-Regular',
					'sans-serif'
				].join(','),
				textTransform: 'capitalize',
			},
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Muli-Regular',
			'sans-serif'
		].join(','),
		fontSize: '14',
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
			disableTouchRipple: true,
		},
	},
})

const styles = theme => ({
	margin: {
		margin: '0',
		padding: '3px 5px 5px 5px',
	},
	cssRoot: {
		minWidth: '0',
		minHeight: '0',
		lineHeight: 'inherit',
		fontSize: '14px',
		color: '#fff',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'underline',
		},
	},
})

class SiteSelectorComponent extends Component {
	constructor(props) {
		super(props)

		this.state = {
			anchorEl: null,
		}

		this.handleClick = event => {
			this.setState({ anchorEl: event.currentTarget })
		}

		this.handleClickInside = event => {
			window.open(event.currentTarget.getAttribute('data-url'), '_blank')
			this.setState({ anchorEl: null })
		}

		this.handleClose = () => {
			this.setState({ anchorEl: null })
		}
	}

	render() {
		const { classes } = this.props
		const { anchorEl } = this.state

		return (
			<MuiThemeProvider theme={theme}>
				<span className='language-selector'>
					<Button
						className={classNames(classes.margin, classes.cssRoot)}
						aria-owns={anchorEl ? 'language-menu' : null}
						aria-haspopup='true'
						onClick={this.handleClick}
					>

					{this.props.currentSite}
					</Button>

					<Menu
						id='language-menu'
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={this.handleClose}
					>

					{this.props.availableSites.map((site, index) =>
						<MenuItem key={index} onClick={this.handleClickInside} data-url={site.Url}>{site.Title}</MenuItem>
					)}
					</Menu>
				</span>
			</MuiThemeProvider>
		)
	}
}

SiteSelectorComponent.propTypes = {
	currentSite: PropTypes.string,
	availableSites: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		url: PropTypes.string
	})),
}

export default withStyles(styles)(SiteSelectorComponent)