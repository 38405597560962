import { NEXT_STEP, RESET_TO_STEP, INIT_STEPS, RESET_ALL } from '../Constants/action-types'
import { states } from '../Constants/states'
import { getAvailableStates } from '../Constants/transitions'
import { trackEvent } from 'Scripts/Shared/analyticsEventTracking'

const initialState = {
	currentState: [states.start],
	availableStates: [states.cart],
	allStates: {}
}

const stateMachine = (state = initialState, action) => {
  switch (action.type) {
	case INIT_STEPS:
		return Object.assign({}, state, { allStates: action.steps })
	case NEXT_STEP: 
		let newState = transitionTo(state.currentState, action.payload)
		return Object.assign({}, state, { currentState: newState, availableStates: getAvailableStates(newState) })
	case RESET_TO_STEP: 
		let currentStep = action.currentStep
		if(action.currentStep != state.currentState){
			trackEvent('Order', 'reset', currentStep, null)
		}
		return Object.assign({}, state, { currentState: currentStep, availableStates: getAvailableStates(currentStep) })	
	case RESET_ALL:
		return initialState
	default:
      return state
  }
}

const checkState = (available, desired) => {
    if (available.includes(desired)) {
      	return desired
    } else {
      	throw new Error(`Desired state: ${desired} is not available`)
    }
}

const transitionTo = (current, desired) => {
    let available = getAvailableStates(current)
    return checkState(available, desired)
}




export default stateMachine