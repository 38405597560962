import LocalStorageHelper from 'Scripts/Helpers/localStorageHelper'
import MonitorService from 'Scripts/Shared/monitorService'
import { refreshTokenName } from './bankIDTokenNames'

class TokenParser {
	constructor() {
		this.monitorService = new MonitorService()
	}

	parseToken = (token) => {
		try {
			return JSON.parse(decodeURIComponent(escape(atob(token.split('.')[1]))))	
		} catch (err) {
			this.monitorService.trackException(new Error('Failed to parse token'), { token: token })
			return {}
		}	
	}

	getMeta = (token) => this.parseToken(token).meta
	getRole = (token) => this.parseToken(token).roles
	getExpirationDate = (token) => this.parseToken(token).expireDate
}

export default class BankIdTokenHandler {
	constructor(tokenName) {
		this.storage = new LocalStorageHelper()
		this.tokenParser = new TokenParser()
		this.tokenName = tokenName
	}

	getPersonalIdentityNumber() {
		const token = this.storage.get(this.tokenName)
		if(token) {
			const meta = this.tokenParser.getMeta(token)
			const personalIdentityNumber = meta && meta.personalIdentityNumber
			return personalIdentityNumber
		}
		return null
	}

	isValid() {
		const token = this.storage.get(this.tokenName)
		if(token) {
			const expirationDate = this.tokenParser.getExpirationDate(token)
			const isValid =  expirationDate && new Date(expirationDate.valueOf()) > Date.now()
			return isValid
		}
		return false
	}

	getBankIdentifier() {
		const token = this.storage.get(this.tokenName)
		if(token) {
			const meta = this.tokenParser.getMeta(token)
			const bank = meta && meta.bank
			return bank
		}
		return null
	}

	getDisplayName() {
		const token = this.storage.get(this.tokenName)
		if(token) {
			const meta = this.tokenParser.getMeta(token)
			const displayName = meta && meta.displayName
			return displayName
		}
		return ''
	}

	isValidTokenAndCustomer() {
		const token = this.storage.get(this.tokenName)
		if(token) {
			const roles = this.tokenParser.getRole(token)
			const isCustomer = roles && roles.includes('customer')
			const isValid = this.isValid()

			return isCustomer && isValid
		}
		return false
	}

	isLoggedIn = () => this.isCustomer;

	isCustomer() {
		const token = this.storage.get(this.tokenName)
		if (token) {
			const roles = this.tokenParser.getRole(token)
			return roles && roles.includes('customer')
		}
		return false
	}

	get = () => this.storage.get(this.tokenName)

	delete () {
		this.storage.delete(this.tokenName)
		this.storage.delete(refreshTokenName)
	}
}