import { states } from './states'
let instance = null

export default class TransitionsSingleton {
	constructor(){
	  if(!instance){
		instance = this
	  }

	  this.steps = {
		  [states.mainInformation]:{childStates:[states.personalInformation]},
		  [states.personalInformation]:{childStates:[states.pepQuestions]},
		  [states.pepQuestions]:{childStates:[states.taxQuestions]},
		  [states.taxQuestions]:{childStates:[states.fellowInformation]},
		  [states.fellowInformation]:{childStates:[states.accountQuestions, states.personalInformation2]},
		  [states.personalInformation2]:{childStates:[states.pepQuestions2]},
		  [states.pepQuestions2]:{childStates:[states.taxQuestions2]},
		  [states.taxQuestions2]:{childStates:[states.accountQuestions]},
		  [states.accountQuestions]:{childStates:[states.confirmation]},
		  [states.confirmation]:{childStates:[states.done]}
	  }

	  return instance
	}

	setSteps(steps) {
		this.steps = steps
	}

	getAvailableStates(current) {
		return this.steps[current].childStates.concat()
	}

	getMetaInformation(current) {
		return this.steps ? this.steps[current].metaInformation : {} 
	}
  
}

export const getMetaInformation = (current) => {
	let transitions = new TransitionsSingleton()
	return transitions.getMetaInformation(current)
}

export const getAvailableStates = (current) => {
	let transitions = new TransitionsSingleton()
	return transitions.getAvailableStates(current)
}
