import { INIT_PAYMENT, TOGGLE_PAYMENT, UPDATE_PAYMENT, 
	PAYMENT_DONE, RESET_PAYMENT, RESET_ALL } from '../Constants/action-types'
import {status, state} from '../Constants/sharedInitialState'
import { trackEvent } from 'Scripts/Shared/analyticsEventTracking'

const initialState =  Object.assign({
	id: 'payment',
	status
  }, state)

const payment = (state = initialState, action) => {
	switch (action.type) {
		case INIT_PAYMENT: 
			return Object.assign({}, state, { 
				availableSteps: Object.assign([], action.availableSteps),
				parentStep: action.parentStep,
				status: Object.assign({}, state.status, {minimized : false, active: true, disabled:false, done:false})  
			})
		case UPDATE_PAYMENT: 
			trackEvent('Order', `paymentChange (${action.choosenStep})`, 'payment', null)
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done:false}),
				choosenStep: action.choosenStep  
			})
		case TOGGLE_PAYMENT:
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, 
					{minimized: action.payload != undefined ? action.payload : !state.status.minimized})  
			})
		case PAYMENT_DONE: 
			return Object.assign({}, state, { 
				status: Object.assign({}, state.status, {done: true, minimized: true, active: false}),
				choosenStep: action.choosenStep
			})
		case RESET_PAYMENT:
		case RESET_ALL:
			return initialState
    	default:
			return state
  }
}


export default payment